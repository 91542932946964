import React from 'react';
import { PropsOf } from '@voleer/types';
import { Box } from 'grommet';
import styled from 'styled-components';

type FormContainerProps = Readonly<PropsOf<typeof Box>>;

const Container = styled(Box)`
  ${props => {
    return `
      @media only screen and (max-width: ${props.theme.global.breakpoints.xsmall.value}px) {
        border-radius: 0;
      }
      border-radius: ${props.theme.global.edgeSize.medium};
    `;
  }}
`;

/**
 * Renders a container that wraps a form (i.e. `RegisterForm`, `ShortTokenForm`).
 */
export const FormContainer: React.FC<FormContainerProps> = props => {
  return (
    <Container background="white" pad="medium" responsive={false} {...props} />
  );
};

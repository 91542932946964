import { isString } from 'util';
import { getValueAndUnit } from 'polished';

/**
 * Given a base size and scale ratio, returns the size value at the given step
 * in the scale.
 *
 * @param base base size from which to scale
 * @param ratio the ratio to scale by
 * @param steps step number on the scale
 */
export const toScale = <TBase extends number | string>(
  base: TBase,
  ratio: number,
  steps: number
): TBase => {
  if (isString(base)) {
    const [value, unit] = getValueAndUnit(base);
    const normalizedBase = Number(value);
    const resultValue = Math.pow(ratio, steps) * normalizedBase;
    return `${resultValue}${unit || ''}` as TBase;
  } else {
    const normalizedBase = Number(base);
    const resultValue = Math.pow(ratio, steps) * normalizedBase;
    return resultValue as TBase;
  }
};

import React from 'react';
import { Link } from '@voleer/ui-kit';
import { Button } from 'grommet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { urlFor } from '../../../../App';

const RegisterButton = styled(Button)`
  ${props => `
    @media only screen and (max-width: ${props.theme.global.breakpoints.small.value}px) {
      padding-left: ${props.theme.global.edgeSize.medium};
      padding-right: ${props.theme.global.edgeSize.medium};
    }

    border-radius: ${props.theme.global.edgeSize.xsmall};
    font-size: 16px;    
    padding-bottom: ${props.theme.global.edgeSize.xsmall};
    padding-top: ${props.theme.global.edgeSize.xsmall};
  `}
`;

type RegisterLinkProps = Readonly<{
  nextUrl: string | null;
  utm: string | null;
}>;

/**
 * Renders the register link styled to look like a button in the select-site page.
 */
export const RegisterLink: React.FC<RegisterLinkProps> = ({ nextUrl, utm }) => {
  const [t] = useTranslation('pages/SelectSitePage');

  return (
    <Link
      data-testid="register-link"
      to={urlFor('registration')({
        nextUrl,
        utm,
      })}
      variation="primary"
    >
      <RegisterButton label={t('sign-up')} primary={true} />
    </Link>
  );
};

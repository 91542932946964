import React, { useEffect } from 'react';
import { Box, Image, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteChildrenProps, useLocation } from 'react-router';
import styled, { keyframes } from 'styled-components';
import {
  ConfirmEmailComponent,
  SendEmailConfirmationCodeComponent,
} from '../../__generated__/graphql-code-generator';
import { urlFor } from '../../App';
import { PageContainer } from '../../components';
import {
  ShortTokenForm,
  ShortTokenFormValues,
} from '../../components/authentication/ShortTokenForm/ShortTokenForm';
import CONFIRM_EMAIL_PAGE_IMG_SRC from './images/confirm-email.png';
import LOADING_IMG_SRC from './images/loading.svg';

/**
 * URL search query params for the confirm-email page.
 */
export type ConfirmEmailPageQueryParams = {
  userId: string;
  returnUrl: string;
};

type ConfirmEmailPageProps = RouteChildrenProps<ConfirmEmailPageQueryParams>;

const blink = keyframes`
  from {opacity: 1.0;}
  to {opacity: 0.3;}
`;

const LoadingImage = styled<typeof Image>(props => (
  <Image src={LOADING_IMG_SRC} width="150px" {...props} />
))`
  animation: ${blink} 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

/**
 * Renders the confirm email page.
 */
export const ConfirmEmailPage: React.FC<ConfirmEmailPageProps> = props => {
  const [t] = useTranslation('pages/ConfirmEmailPage');

  const { state: locationState }: { state: { email: string | undefined } } =
    useLocation();

  // Retrieve query params
  const params = new URLSearchParams(props.location.search);
  const userId = params.get('userId');
  const returnUrl = params.get('returnUrl') || '';

  useEffect(() => {
    if (!window || !window.pendo) {
      return;
    }
    window.pendo.identify({
      visitor: {
        id: userId,
      },
    });
  }, [userId]);

  if (!userId) {
    return <Redirect to={urlFor('registration')({ nextUrl: returnUrl })} />;
  }

  return (
    <PageContainer width={{ max: '600px' }}>
      <ConfirmEmailComponent>
        {(confirmEmailMutation, { data }) => {
          const onSubmit = async (values: ShortTokenFormValues) => {
            try {
              const response = await confirmEmailMutation({
                variables: {
                  input: {
                    userId,
                    verificationCode: values.shortToken,
                  },
                },
              });

              if (response && response.data && !response.data.confirmEmail) {
                return Promise.reject(t('email-confirmation-rejected'));
              }
              return Promise.resolve();
            } catch {
              return Promise.reject(t('email-confirmation-rejected'));
            }
          };

          if (data && data.confirmEmail && data.confirmEmail.userId) {
            window.location.href = returnUrl;

            return (
              <Box fill="vertical" justify="center" pad="medium">
                <LoadingImage alignSelf="center" />

                <Paragraph
                  margin={{ vertical: 'small' }}
                  size="xlarge"
                  textAlign="center"
                >
                  {t('redirecting')}
                </Paragraph>
              </Box>
            );
          }

          return (
            <SendEmailConfirmationCodeComponent>
              {sendConfirmEmailCodeMutation => {
                const onCodeResend = async () => {
                  const response = await sendConfirmEmailCodeMutation({
                    variables: {
                      input: {
                        returnUrl,
                        userId,
                      },
                    },
                  });

                  if (
                    response &&
                    response.data &&
                    response.data.sendEmailConfirmationCode &&
                    response.data.sendEmailConfirmationCode.userId
                  ) {
                    return t('new-code-sent');
                  }
                  return Promise.reject(t('error-sending-code'));
                };

                return (
                  <ShortTokenForm
                    email={locationState?.email}
                    onCodeResend={onCodeResend}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </SendEmailConfirmationCodeComponent>
          );
        }}
      </ConfirmEmailComponent>

      <Image
        alt={t('image.alt-text')}
        margin={{ top: 'small' }}
        src={CONFIRM_EMAIL_PAGE_IMG_SRC}
        width="100%"
      />
    </PageContainer>
  );
};

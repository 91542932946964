import React, { Dispatch, SetStateAction, useState } from 'react';
import { PropsOf } from '@voleer/types/src';
import { Alert } from '@voleer/ui-kit';
import { Button } from 'grommet';
import styled from 'styled-components';

/**
 * Prop types for the `ResendCodeButton` component.
 */
type ResendCodeButtonProps = PropsOf<typeof Button> & {
  /**
   * The function that will be called when the button is clicked.
   * Returning a string in the promise will display it as a success message
   * if the promise is resolved or an error message if the promise is rejected.
   */
  onCodeResend: () => Promise<string>;
};

const _onSubmit =
  (
    onSubmit: () => Promise<string>,
    setIsSubmitting: Dispatch<SetStateAction<boolean>>,
    setSuccessMessage: Dispatch<SetStateAction<string>>,
    setErrorMessage: Dispatch<SetStateAction<string>>
  ) =>
  async () => {
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const message = await onSubmit();
      setSuccessMessage(message || '');
    } catch (error) {
      setErrorMessage(error || '');
    } finally {
      setIsSubmitting(false);
    }
  };

const StyledButton = styled(Button)`
  text-decoration: underline;
`;

/**
 * Component that renders a button that expects an async operation to be
 * performed in its onCodeResend callback.
 *
 * The button will be disabled while waiting for the async operation to be
 * completed.
 *
 * Returning a string in the promise returned by onCodeResend will display the
 * string as a success message if the promise is resolved or an error
 * message if the promise is rejected.
 *
 * @example
 * ```javascript
 * const onCodeResend = async () => {
 *   await wait(500);
 *   return Promise.resolve('Success Message');
 * };
 *
 * return (
 *   <ResendCodeButton onCodeResend={onCodeResend} label="A simple button" />
 * );
 * ```
 *
 * @param props @see ResendCodeButtonProps
 */
export const ResendCodeButton: React.FC<ResendCodeButtonProps> = ({
  onCodeResend,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <StyledButton
        disabled={isSubmitting}
        {...props}
        onClick={_onSubmit(
          onCodeResend,
          setIsSubmitting,
          setSuccessMessage,
          setErrorMessage
        )}
      />

      {successMessage && (
        <Alert margin={{ top: 'small' }} status="info">
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert margin={{ top: 'small' }} status="error">
          {errorMessage}
        </Alert>
      )}
    </>
  );
};

import { useEffect, useState } from 'react';

export type DeterminiateState = 'off' | 'on';
export type TransitionalState = 'off-transition' | 'on-transition';
export type ToggleState = DeterminiateState | TransitionalState;
type ToggleFn = () => void;
type ToggleStateValues = [ToggleState, ToggleFn];

const isTransitionalState = (
  state: ToggleState
): state is TransitionalState => {
  const transitionalStates: TransitionalState[] = [
    'on-transition',
    'off-transition',
  ];
  const maybeTransitionalState = state as TransitionalState;
  return transitionalStates.includes(maybeTransitionalState);
};

/**
 * Hook for toggling between an 'on' or 'off' state.
 *
 * Optionally provides duration support. If a duration is specified then a
 * transition state will be entered while transitioning to 'on' or 'off' while
 * the duration is active.
 *
 * @example
 * ```javascript
 * const [toggleState, toggle] = useToggleState();
 * console.log(toggleState); // 'on'
 * toggle();
 * console.log(toggleState); // 'off'
 * ```
 *
 * @example
 * ```javascript
 * const [toggleState, toggle] = useToggleState('on', { duration: 500 });
 * console.log(toggleState); // 'on'
 * toggle();
 * console.log(toggleState); // 'off-transition'
 * // -- wait 500ms --
 * console.log(toggleState); // 'off'
 * ```
 *
 * @param initialState either 'on' or 'off', defaults to 'on'
 * @param options
 */
export const useToggleState = (
  initialState: DeterminiateState = 'on',
  options: {
    duration?: number;
  } = {}
): ToggleStateValues => {
  const [state, setState] = useState<ToggleState>(initialState);
  const { duration } = options;

  // Trigger a timeout to move to the next transition
  useEffect(() => {
    let timeout: number;
    if (isTransitionalState(state)) {
      timeout = setTimeout(() => {
        setState(state === 'off-transition' ? 'off' : 'on');
      }, duration);
    }
    return () => clearTimeout(timeout);
  }, [state, duration]);

  const toggle = () => {
    if (duration && duration > 0) {
      setState(state === 'on' ? 'off-transition' : 'on-transition');
    } else {
      setState(state === 'on' ? 'off' : 'on');
    }
  };

  return [state, toggle];
};

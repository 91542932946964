import urlParse from 'url-parse';

/**
 * Checks whether we are currently running on a browser.
 */
export const isBrowser = (): boolean => {
  if (!process) {
    return true;
  }

  return !!process.browser;
};

/**
 * Checks whether we are running in test mode (i.e. Jest).
 */
export const isTest = (): boolean => {
  return process?.env.NODE_ENV === 'test';
};

/**
 * Checks whether we are running in production mode.
 */
export const isProduction = () => {
  return process?.env.NODE_ENV === 'production';
};

/**
 * Checks whether we are currently running on the server.
 */
export const isServer = (): boolean => {
  return !!process && !process.browser;
};

/**
 * Returns the base URL for the given URL, or for the current browser URL if no
 * URL is provided.
 *
 * ```
 * https://something.bittitan.com:123/some/page.html => https://something.bittitan.com:123
 * ```
 *
 * @param [url = window.location.href]
 */
export const getBaseUrl = (url: string = window.location.href) => {
  const parsed = urlParse(url);
  return parsed.origin;
};

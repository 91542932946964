import React, { useState } from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert, Anchor } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import {
  Box,
  Button,
  CheckBox,
  FormField,
  Heading,
  Paragraph,
  TextInput,
} from 'grommet';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormContainer } from '../FormContainer';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  companyName: '',
  siteDomain: '',
};

const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
const TERMS_OF_SERVICE_LINK = 'https://go.voleer.com/tos';

const I18N_NAMESPACE = 'components/authentication/RegisterForm';

export type RegisterFormValues = typeof defaultValues;

type RegisterFormProps = {
  /**
   * Submit handler.
   */
  onSubmit: (
    values: RegisterFormValues,
    actions: FormikHelpers<RegisterFormValues>
  ) => Promise<void>;

  /**
   * Validator function to check if the given domain is available.
   */
  uniqueDomainValidator: (domain: string) => Promise<boolean> | boolean;

  /**
   * Root domain of the Voleer environment (i.e. voleer.io, voleer.dev, etc.).
   */
  rootDomain: string;
};

const validationSchema = ({
  t,
  uniqueDomainValidator,
}: {
  t: typeof i18next.t;
  uniqueDomainValidator: RegisterFormProps['uniqueDomainValidator'];
}) => {
  return Yup.object().shape({
    companyName: Yup.string()
      .trim()
      .required(t('validation.company-name-required')),
    firstName: Yup.string()
      .trim()
      .required(t('validation.first-name-required')),
    lastName: Yup.string().trim().required(t('validation.last-name-required')),
    email: Yup.string()
      .required(t('validation.email-required'))
      .email(t('validation.email-invalid')),
    siteDomain: Yup.string()
      .required(t('validation.domain-required'))
      .test(
        'num-characters',
        t('validation.domain-invalid.num-characters'),
        (value?: string) => {
          if (!value) {
            return false;
          }

          return value.length >= 4 && value.length <= 63;
        }
      )
      .matches(/^[a-z0-9-]+$/, {
        message: t('validation.domain-invalid.allowed-characters'),
      })
      .matches(/^([a-z0-9]+-*)*[a-z0-9]+$/, {
        message: t('validation.domain-invalid.hyphens'),
      })
      .test('unique-domain', t('validation.domain-not-unique'), async value => {
        if (typeof value !== 'string') {
          return false;
        }
        return await uniqueDomainValidator(value);
      }),
    password: Yup.string()
      .required(t('validation.password-required'))
      .matches(passwordRegExp, t('validation.password-invalid')),
  });
};

const handleFormSubmit =
  (onSubmit: FormikSubmitFn<RegisterFormValues>) =>
  async (
    values: RegisterFormValues,
    formikActions: FormikHelpers<RegisterFormValues>
  ) => {
    try {
      await onSubmit(values, formikActions);
    } catch (error) {
      formikActions.setSubmitting(false);
      formikActions.setStatus({
        serverError: error,
      });
    }
  };

/**
 * Renders the register form.
 */
export const RegisterForm: React.FC<RegisterFormProps> = ({
  onSubmit,
  rootDomain,
  uniqueDomainValidator,
}) => {
  const [t] = useTranslation(I18N_NAMESPACE);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const toggleHasAcceptedTerms = () => setHasAcceptedTerms(!hasAcceptedTerms);
  const [hasAcceptedFreeTrial, setHasAcceptedFreeTrial] = useState(false);
  const toggleHasAcceptedFreeTrial = () =>
    setHasAcceptedFreeTrial(!hasAcceptedFreeTrial);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormContainer margin={{ horizontal: 'auto' }} width={{ max: '525px' }}>
      <Formik
        initialValues={defaultValues}
        onSubmit={handleFormSubmit(onSubmit)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema({ uniqueDomainValidator, t })}
      >
        {formProps => (
          <Form>
            {formProps.status && formProps.status.serverError && (
              <Alert status="error">{formProps.status.serverError}</Alert>
            )}

            <Heading color="neutral-3" level="2" size="xsmall">
              {t('heading.set-tenant')}
            </Heading>
            <Field name="companyName">
              {(fieldProps: FieldProps<string>) => {
                return (
                  <FormField
                    error={
                      fieldProps.form.touched.companyName &&
                      fieldProps.form.errors.companyName
                    }
                    label={t('form.company-name.label')}
                  >
                    <TextInput {...fieldProps.field} />
                  </FormField>
                );
              }}
            </Field>
            <Box direction="row" gap="xsmall">
              <Box fill={true}>
                <Field name="siteDomain">
                  {(fieldProps: FieldProps<string>) => {
                    return (
                      <FormField
                        error={
                          fieldProps.form.touched.siteDomain &&
                          fieldProps.form.errors.siteDomain
                        }
                        label={t('form.site-domain.label')}
                      >
                        <TextInput {...fieldProps.field} />
                      </FormField>
                    );
                  }}
                </Field>
              </Box>

              <Paragraph margin={{ bottom: '0', top: '40px' }}>
                .{rootDomain}
              </Paragraph>
            </Box>

            <Heading
              color="neutral-3"
              level="2"
              margin={{ top: 'medium' }}
              size="xsmall"
            >
              {t('heading.your-info')}
            </Heading>
            <Box direction="row-responsive" gap="small">
              <Box flex={{ grow: 1 }}>
                <Field name="firstName">
                  {(fieldProps: FieldProps<string>) => {
                    return (
                      <FormField
                        error={
                          fieldProps.form.touched.firstName &&
                          fieldProps.form.errors.firstName
                        }
                        label={t('form.first-name.label')}
                      >
                        <TextInput {...fieldProps.field} />
                      </FormField>
                    );
                  }}
                </Field>
              </Box>
              <Box flex={{ grow: 1 }}>
                <Field name="lastName">
                  {(fieldProps: FieldProps<string>) => {
                    return (
                      <FormField
                        error={
                          fieldProps.form.touched.lastName &&
                          fieldProps.form.errors.lastName
                        }
                        label={t('form.last-name.label')}
                      >
                        <TextInput {...fieldProps.field} />
                      </FormField>
                    );
                  }}
                </Field>
              </Box>
            </Box>
            <Field name="email">
              {(fieldProps: FieldProps<string>) => {
                return (
                  <FormField
                    error={
                      fieldProps.form.touched.email &&
                      fieldProps.form.errors.email
                    }
                    label={t('form.email.label')}
                  >
                    <TextInput {...fieldProps.field} />
                  </FormField>
                );
              }}
            </Field>
            <Field name="password">
              {(fieldProps: FieldProps<string>) => (
                <FormField
                  error={
                    fieldProps.form.touched.password &&
                    fieldProps.form.errors.password
                  }
                  label={
                    <Box direction="row">
                      <Box fill="horizontal">{t('form.password.label')}</Box>
                      <Anchor
                        label={
                          showPassword
                            ? t('password-toggle.hide')
                            : t('password-toggle.show')
                        }
                        onClick={toggleShowPassword}
                        variation="primary"
                      />
                    </Box>
                  }
                >
                  <TextInput
                    {...fieldProps.field}
                    type={showPassword ? 'text' : 'password'}
                  />
                  {!fieldProps.form.errors.password && (
                    <Paragraph color="dark-2" margin={{ top: 'xsmall' }}>
                      {t('password-rules')}
                    </Paragraph>
                  )}
                </FormField>
              )}
            </Field>

            <Box margin={{ vertical: 'medium' }} responsive={false}>
              <CheckBox
                checked={hasAcceptedTerms}
                data-testid="register-form__checkbox--terms-of-service"
                label={
                  <Trans i18nKey="terms-of-service" ns={I18N_NAMESPACE}>
                    I agree to Voleer&nbsp;
                    <a
                      href={TERMS_OF_SERVICE_LINK}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      terms of service
                    </a>
                  </Trans>
                }
                onChange={toggleHasAcceptedTerms}
              />
            </Box>

            <Box margin={{ vertical: 'medium' }} responsive={false}>
              <CheckBox
                checked={hasAcceptedFreeTrial}
                data-testid="register-form__checkbox--acknowledge"
                label={t('free-trial-agreement')}
                onChange={toggleHasAcceptedFreeTrial}
              />
            </Box>

            <Box direction="row">
              <Button
                disabled={
                  !hasAcceptedFreeTrial ||
                  !hasAcceptedTerms ||
                  formProps.isValidating ||
                  formProps.isSubmitting
                }
                label={t('form.submit.label')}
                margin="auto"
                primary={true}
                type="submit"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

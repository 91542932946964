import React, { isValidElement, useState } from 'react';
import { PropsOf, isElementOf } from '@voleer/types';
import { Box, Button, Collapsible, Heading, Text } from 'grommet';
import {
  FaAngleDown,
  FaAngleRight,
  FaCheck,
  FaExclamationTriangle,
  FaInfoCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import { IconType } from 'react-icons/lib/cjs';
import { MdClose } from 'react-icons/md';
import { Icon } from '..';
import { useToggleState } from '../../hooks';

export type AlertStatus = 'error' | 'info' | 'ok' | 'warning';

export type CollapsibleState = 'start-collapsed' | 'start-expanded' | undefined;

/**
 * Computes the icon associated with the given alert status.
 */
const statusIcon = (status?: AlertStatus): IconType => {
  switch (status) {
    case 'ok':
      return FaCheck;
    case 'warning':
      return FaExclamationTriangle;
    case 'error':
      return FaTimesCircle;
    default:
      return FaInfoCircle;
  }
};

/**
 * Computes the color associated with the given alert status.
 */
const statusColor = (status?: AlertStatus): string | undefined => {
  switch (status) {
    case 'ok':
      return 'status-ok';
    case 'info':
      return 'status-info';
    case 'warning':
      return 'status-warning';
    case 'error':
      return 'status-error';
    default:
      return 'status-unknown';
  }
};

/**
 * Renders the dismiss button for an alert.
 */
const DismissButton = (props: PropsOf<typeof Button>) => (
  <Button color="dark-4" icon={<MdClose size={22} />} plain={true} {...props} />
);

type AlertGroupProps = AlertProps;

/**
 * Displays an Alert consisting of one or more child Alerts.
 */
export const AlertGroup = React.forwardRef<HTMLDivElement, AlertGroupProps>(
  ({ children, ...alertProps }, ref) => {
    const updatedChildren = React.Children.map(children, (child, index) => {
      if (!isValidElement(child)) {
        return child;
      }

      if (!isElementOf(Alert, child)) {
        return child;
      }

      const alertGroupChild = React.cloneElement<PropsOf<typeof Alert>>(child, {
        round: child.props.round || false,
        background: child.props.background || 'none',
        border:
          child.props.border || index === 0
            ? false
            : { color: 'border-default', side: 'top' },
      });

      return alertGroupChild;
    });

    return (
      <Alert {...alertProps} ref={ref}>
        {updatedChildren}
      </Alert>
    );
  }
);

type AlertProps = PropsOf<typeof Box> & {
  /**
   * The status of the alert, which controls the color and other aspects.
   */
  status?: AlertStatus;

  /**
   * Display an icon with the alert.
   *
   * If `true` a default icon will be used based on `status`. Otherwise a custom
   * icon component can be provided.
   *
   * ```typescript
   * <Alert status="error" icon={true}>
   *   Default error icon will be displayed.
   * </Alert>
   * ```
   *
   * ```typescript
   * <Alert status="error" icon={FaCheck}>
   *   Custom icon will be displayed.
   * </Alert>
   * ```
   */
  icon?: IconType | boolean;

  /**
   * Custom dismiss handler in case you need to control specifically how the
   * alert gets dismissed.
   */
  dismiss?: () => void;

  /**
   * Controls whether the alert will be displayed or not. Useful if using a
   * custom `dismiss` handler.
   */
  dismissed?: boolean;

  /**
   * Duration of the dismiss animation in milliseconds.
   */
  dismissDuration?: number;

  /**
   * Determines whether the alert can be dismissed or not.
   *
   * @default false
   */
  dismissable?: boolean;

  /**
   * A title for the alert.
   */
  title?: string;

  /**
   * Determines whether the alert is collapsible and sets its initial state.
   */
  collapsible?: CollapsibleState;
};

/**
 * Renders an Alert box.
 */
export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      children,
      dismiss,
      dismissDuration,
      dismissable: propsDismissable,
      dismissed = false,
      status,
      icon: propsIcon,
      pad: propsPad,
      title,
      collapsible,
      ...boxProps
    },
    ref
  ) => {
    const [state, toggle] = useToggleState(dismissed ? 'off' : 'on', {
      duration: dismissDuration,
    });

    const [open, setOpen] = useState(collapsible === 'start-expanded');

    if (dismissed) {
      return null;
    }

    if (state === 'off') {
      return null;
    }

    const dismissable =
      propsDismissable ||
      typeof dismiss === 'function' ||
      typeof dismissDuration === 'number';

    const animation: PropsOf<typeof Box>['animation'] = {
      duration: dismissDuration,
      type: 'fadeOut',
    };

    const useAnimation =
      !!dismissDuration && dismissDuration > 0 && state === 'off-transition';

    const handleDismiss = () => {
      (dismiss || toggle)();
    };

    const icon = propsIcon === true ? statusIcon(status) : propsIcon;

    const renderContent = () => {
      if (typeof title === 'string' && title.length > 0) {
        return (
          <Box>
            <Box align="center" direction="row" gap="small">
              {icon && (
                <Icon
                  color={statusColor(status)}
                  data-testid="alert__title-icon"
                  icon={icon}
                />
              )}
              <Heading
                data-testid="alert__title"
                level={5}
                margin={{ right: 'auto' }}
              >
                {title}
              </Heading>
              <Box
                data-testid="alert__collapse-button"
                hidden={!collapsible}
                onClick={() => setOpen(!open)}
              >
                <Icon icon={open ? FaAngleDown : FaAngleRight} />
              </Box>
            </Box>
            <div data-testid="alert__collapsible">
              <Collapsible open={open}>{renderChildren()}</Collapsible>
            </div>
          </Box>
        );
      }
      return renderChildren();
    };

    const renderChildren = () => {
      if (typeof children === 'string') {
        return (
          <Text data-testid="alert__text" wordBreak="break-word">
            {children}
          </Text>
        );
      }
      return children;
    };

    return (
      <Box
        align="center"
        animation={useAnimation ? animation : undefined}
        background="white"
        border={{ color: statusColor(status), size: '2px' }}
        color={statusColor(status)}
        direction="row"
        height={{ min: 'fit-content' }} // Fixes an issue when multiple <Paragraph> children breaks the height of the alert
        pad={{ horizontal: 'small', vertical: 'xsmall' }}
        round="xsmall"
        {...boxProps}
        ref={ref}
      >
        {icon && (title === undefined || title === '') && (
          <Box flex={{ shrink: 0 }} margin={{ right: 'small' }}>
            <Icon
              color={statusColor(status)}
              data-testid="alert__default-icon"
              icon={icon}
            />
          </Box>
        )}

        <Box width="100%">{renderContent()}</Box>

        {dismissable && (
          <DismissButton
            alignSelf="start"
            margin={{ left: 'auto' }}
            onClick={handleDismiss}
          />
        )}
      </Box>
    );
  }
);

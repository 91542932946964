// Polyfill `fetch`
import 'cross-fetch/polyfill';

import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import introspectionQueryResultData from '../__generated__/fragmentTypes';
import { getBaseUrl, isBrowser } from '../utils/environment';

const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
});

// When running in the browser we need to use `<current-base-url>/graphql`, but
// on the server during SSR we access the API on localhost
const uri = isBrowser()
  ? `${getBaseUrl()}/graphql`
  : 'http://localhost:3000/graphql';

const httpLink = new HttpLink({
  uri,
  fetch,
});

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

/**
 * Creates instance of Apollo Client.
 */
export const createClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache,
  });
};

import React from 'react';
import { Box, Heading, Image } from 'grommet';
import Error404Image from './vee-with-map.png';

type Error404Props = Readonly<{
  /**
   * The heading for the 404 error. Defaults to "404".
   */
  heading?: string;

  /**
   * Instructions and/or links for the user so that they can resolve the error.
   */
  helpContent: React.ReactNode;

  /**
   * The subheading for the 404 error.
   */
  subheading: string;
}>;

/**
 * Renders 404 error content.
 */
export const Error404: React.FC<Error404Props> = ({
  heading = '404',
  helpContent,
  subheading,
}) => {
  return (
    <Box
      align="center"
      direction="row"
      fill={true}
      justify="center"
      margin={{ top: '-15vh' }}
    >
      <Image a11yTitle="404 Page not found" height={250} src={Error404Image} />

      <Box margin={{ left: 'large' }}>
        <Heading
          color="accent-1"
          margin={{ bottom: '-12px', top: 'none' }}
          size="xlarge"
        >
          {heading}
        </Heading>

        <Heading level="3">{subheading}</Heading>

        {helpContent}
      </Box>
    </Box>
  );
};

import React, { ReactElement } from 'react';
import { html } from 'common-tags';
import { Schema } from 'hast-util-sanitize';
import githubSchema from 'hast-util-sanitize/lib/github.json';
import h from 'hastscript';
import { cloneDeep, memoize } from 'lodash';
import { Paragraph } from 'mdast';
import { ContainerDirective, LeafDirective } from 'mdast-util-directive';
import rehypeRaw from 'rehype-raw';
import rehype2react, { ComponentLike } from 'rehype-react';
import rehypeSanitize from 'rehype-sanitize';
import remarkDirective from 'remark-directive';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remark2rehype from 'remark-rehype';
import unified, { Plugin } from 'unified';
import visit from 'unist-util-visit';
import { CollapsibleState } from '../Alert';

// Clobber prefix to use during sanitization
const clobberPrefix = 'safe-md-';

// Clone the GitHub sanitization schema from `hast-util-sanitize` so we avoid
// modifying the original one
const baseSanitizeSchema = cloneDeep(githubSchema as unknown as Schema);

// We will be using data attributes to store information on tags.
const safeCustomAttributes = ['data*'];
baseSanitizeSchema.attributes?.['div'].push(...safeCustomAttributes);

/**
 * Default sanitization schema based on GitHub's sanitization rules.
 */
const defaultSanitizeSchema = Object.assign<Schema, Schema, Schema>(
  {},
  baseSanitizeSchema,
  {
    clobberPrefix,
  }
);

/**
 * Unified plugin for adding support for the `:::escape` directive.
 *
 * Minimal usage (requires remark-parse and remark-directive):
 *
 * ```typescript
 * import remarkParse from 'remark-parse';
 * import remarkDirective from 'remark-directive';
 *
 * const processor = unified()
 *   .use(remarkParse)
 *   .use(remarkDirective)
 *   .use(escapeDirective);
 * ```
 */
const escapeDirective: Plugin = function () {
  return (tree, file) => {
    const getFileLines = memoize((): string[] => {
      return file.contents.toString().split(/\r?\n/g);
    });

    visit(tree, { type: 'containerDirective', name: 'escape' }, escapeNode => {
      // Visit each paragraph contained in the escape directive and replace its
      // content with the text content from the original markdown
      visit(escapeNode, 'paragraph', (paragraphNode: Paragraph) => {
        const start = paragraphNode.position?.start.line;
        const end = paragraphNode.position?.end.line;
        if (typeof start === 'undefined' || typeof end === 'undefined') {
          return;
        }

        const fileLines = getFileLines();

        // Type definition for 'paragraphNode' has issues
        paragraphNode.children = [
          {
            type: 'text',
            value: fileLines.slice(start - 1, end).join('\n'),
          },
        ];
      });
    });
  };
};

// The handling of directives are done according to the following proposal:
// https://talk.commonmark.org/t/generic-directives-plugins-syntax/444

/**
 * Plugin to handle the signature, ::progress{label=[string] value=[number] thin=[boolean]}.
 * Converts into a div element decorated with custom attributes.
 */
const progressInlineDirective: Plugin = function () {
  return tree => {
    visit(tree, { type: 'leafDirective', name: 'progress' }, progressNode => {
      // The library itself is not describing type properly so we can justify the recast
      // NOTE: Package added 'mdast-util-directive' to be able to resolve type 'LeafDirective'
      const leafDirective = progressNode as unknown as LeafDirective;

      const label = leafDirective.attributes?.['label'];
      const value = leafDirective.attributes?.['value'];
      const thin = leafDirective.attributes?.['thin'];

      leafDirective.children = [
        {
          type: 'html',
          value: html`<div
            data-component="progress"
            data-label="${label}"
            data-value="${value}"
            data-thin="${String(!!thin)}"
          />`,
        },
      ];
    });
  };
};

/**
 * The names of directives that represent markdown admonitions.
 */
const ADMONITION_NODE_NAMES = ['note', 'danger', 'warning'] as const;
export type MarkdownAdmonitionDirectiveName =
  typeof ADMONITION_NODE_NAMES[number];

/**
 * Plugin to handle the signature, :::note/warning/danger{title=[string]}.
 * Converts into a div element decorated with custom attributes.
 */
const admonitionContainerDirective: Plugin = function () {
  return tree => {
    visit(tree, { type: 'containerDirective' }, (node: ContainerDirective) => {
      if (
        !(ADMONITION_NODE_NAMES as ReadonlyArray<string>).includes(node.name)
      ) {
        return;
      }

      let collapsible: CollapsibleState = undefined;
      if (node.attributes?.expanded === 'true') {
        collapsible = 'start-expanded';
      } else if (node.attributes?.expanded === 'false') {
        collapsible = 'start-collapsed';
      }

      const type = node.name;
      const data = node.data || (node.data = {});
      const hast = h('div', {
        dataComponent: 'admonition',
        dataType: `${type}`,
        dataTitle: node.attributes?.title,
        dataCollapsible: collapsible,
      });

      data.hName = hast.tagName;
      data.hProperties = hast.properties;
    });
  };
};

type CreateElementFn = typeof React.createElement;

type RehypeReactOptions = rehype2react.Options<CreateElementFn>;

export type SafeMarkdownProps = {
  /**
   * Sanitization schema for `hast-util-sanitize`, allowing custom sanitization
   * rules to be used.
   *
   * See docs: https://github.com/syntax-tree/hast-util-sanitize
   */
  sanitizeSchema?: Schema;

  /**
   * Override default elements (such as <a>, <p>, etcetera) by passing an
   * object mapping tag names to components.
   *
   * See docs: https://github.com/rehypejs/rehype-react#optionscomponents
   */
  components?: Record<string, ComponentLike<ReturnType<CreateElementFn>>>;

  /**
   * Fragment component to wrap content in. Defaults to wrapping content in
   * `<div>`. To disable, pass `React.Fragment`.
   *
   * See docs: https://github.com/rehypejs/rehype-react#optionsfragment
   */
  Fragment?: RehypeReactOptions['Fragment'];

  /**
   * Markdown content to parse.
   */
  content: string;
};

type SafeMarkdownStaticProps = {
  /**
   * Default schema used for HTML sanitization.
   */
  defaultSanitizeSchema: Readonly<Schema>;

  /**
   * Clobber prefix for prefixing reserved HTML attributes during sanitization.
   *
   * Since attributes like `name` and `id` are expected to be unique on the
   * page, they are prefixed in order to prevent user-generated content from
   * conflicting with other unique attributes elsewhere on the page.
   */
  clobberPrefix: string;
};

/**
 * Renders markdown and sanitizes the resulting HTML, making it safe to display
 * user-generated markdown content.
 */
export const SafeMarkdown: React.FC<SafeMarkdownProps> &
  SafeMarkdownStaticProps = ({
  content,
  components,
  Fragment,
  sanitizeSchema,
}) => {
  const schema = sanitizeSchema || defaultSanitizeSchema;

  const processor = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkDirective)
    .use(escapeDirective)
    .use(progressInlineDirective)
    .use(admonitionContainerDirective)
    .use(remark2rehype, { allowDangerousHtml: true })
    .use(rehypeRaw)
    .use(rehypeSanitize, schema)
    .use(rehype2react, {
      createElement: React.createElement,
      Fragment,
      components,
    });

  // The cast is because the types for vfile do not have `result` as a
  // property, but that is where unified places `.process()` results.
  // See: https://github.com/rehypejs/rehype-react#originuserehype2react-options
  const result = processor.processSync(content).result as ReactElement;

  return <>{result}</>;
};

// Expose the default sanitization schema
SafeMarkdown.defaultSanitizeSchema = defaultSanitizeSchema;

// Expose clobberPrefix
SafeMarkdown.clobberPrefix = clobberPrefix;

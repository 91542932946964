import React from 'react';
import { PropsOf } from '@voleer/types/src';
import { Anchor as GrommetAnchor } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled from 'styled-components';

export type AnchorProps = PropsOf<typeof GrommetAnchor> & {
  variation: 'primary' | 'secondary' | 'unstyled';
};

const AnchorComponent: React.FC<AnchorProps> = ({ ...props }) => {
  return <GrommetAnchor {...props} />;
};

/**
 * Extension of a Grommet Anchor that expose multiple type for different style presets.
 */
export const Anchor = styled(AnchorComponent)`
  text-decoration: ${props => {
    switch (props.variation) {
      case 'primary':
        return 'underline';
      case 'secondary':
      case 'unstyled':
        return 'none';
    }
  }};
  font-weight: inherit;
  color: ${props => {
    switch (props.variation) {
      case 'primary':
        return normalizeColor('brand', props.theme);
      case 'secondary':
        return normalizeColor('dark-2', props.theme);
      case 'unstyled':
        return 'inherit';
    }
  }};
  &:hover {
    text-decoration: ${props => {
      switch (props.variation) {
        case 'primary':
        case 'secondary':
          return 'underline';
        case 'unstyled':
          return 'none';
      }
    }};
    color: ${props => {
      switch (props.variation) {
        case 'primary':
        case 'secondary':
          return normalizeColor('brand', props.theme);
        case 'unstyled':
          return 'inherit';
      }
    }};
  }
`;

/**
 * Type guard to check if the given value is not null or undefined.
 *
 * @param value
 */
export const isNotNullOrUndefined = <T>(
  value: T | null | undefined
): value is T => {
  return value !== null && typeof value !== 'undefined';
};

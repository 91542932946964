import React from 'react';
import { Anchor } from '@voleer/ui-kit';
import { Box, Paragraph } from 'grommet';
import { Trans } from 'react-i18next';
import { urlFor } from '../../../../App';

/**
 * Renders text instructing the user how to contact sales on the registration page.
 */
export const ContactSalesText: React.FC = () => {
  return (
    <Box
      align="center"
      margin={{ horizontal: 'auto', top: 'auto' }}
      width={{ max: '550px' }}
    >
      <Paragraph margin={{ top: 'medium' }} textAlign="center">
        <Trans i18nKey="contact-sales" ns="pages/RegisterPage">
          <>
            Interested in customized tools, advanced customer support, or
            onboarding and training?&nbsp;
          </>
          <Anchor href={urlFor('contactSales')()} variation="primary">
            Contact our sales team
          </Anchor>
          <>
            &nbsp; to learn more about our premium plans and find the one that’s
            right for you!
          </>
        </Trans>
      </Paragraph>
    </Box>
  );
};

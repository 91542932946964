import { EOL } from 'os';
import { left } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { Reporter } from 'io-ts/lib/Reporter';

/**
 * Converts io-ts validation `Errors` into an `Error` containing the message
 * from the first validation error and immediately throws the resulting error.
 *
 * @param errors io-ts Errors
 * @param [reporter=PathReporter] io-ts reporter to use
 */
export const throwErrors = (
  errors: t.Errors,
  reporter: Reporter<string[]> = PathReporter
) => {
  throw reportToError(errors, reporter);
};

/**
 * Converts io-ts validation `Errors` to an `Error` containing the message from
 * the first validation error.
 *
 * @param errors io-ts Errors
 * @param [reporter=PathReporter] io-ts reporter to use
 */
export const reportToError = (
  errors: t.Errors,
  reporter: Reporter<string[]> = PathReporter
) => {
  return reportToErrors(errors, reporter)[0];
};

/**
 * Converts io-ts validation `Errors` to an array of `Error` instances.
 *
 * @param errors io-ts Errors
 * @param [reporter=PathReporter] io-ts reporter to use
 */
export const reportToErrors = (
  errors: t.Errors,
  reporter: Reporter<string[]> = PathReporter
): Error[] => {
  return reporter.report(left(errors)).map(message => new Error(message));
};

/**
 * Converts io-ts `Errors` into a string containing the message from each
 * validation error separated by newlines.
 *
 * @param errors io-ts Errors
 * @param [reporter=PathReporter] io-ts reporter to use
 */
export const reportToString = (
  errors: t.Errors,
  reporter: Reporter<string[]> = PathReporter
) => {
  return reporter.report(left(errors)).join(EOL);
};

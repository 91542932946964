import React from 'react';
import { PropsOf } from '@voleer/types';
import { Box, Heading, Layer } from 'grommet';
import { Area, AreaProps } from '..';

type BoxProps = PropsOf<typeof Box>;
type LayerProps = PropsOf<typeof Layer>;

const ModalHeader: React.FC<BoxProps> = ({ children, ...props }) => {
  const renderChildren = () => {
    if (typeof children === 'string') {
      return (
        <Heading level="3" margin="none">
          {children}
        </Heading>
      );
    }

    return children;
  };
  return (
    <Box flex={{ shrink: 0 }} {...props}>
      {renderChildren()}
    </Box>
  );
};

const ModalBody: React.FC<BoxProps> = props => <Box {...props} />;

const ModalFooter: React.FC<BoxProps> = props => {
  return (
    <Box
      align="center"
      direction="row"
      gap="small"
      justify="end"
      margin={{ top: 'small' }}
      {...props}
    />
  );
};

export type ModalProps = AreaProps &
  Pick<LayerProps, 'onClickOutside' | 'onEsc' | 'position'> & {
    className?: string;
    onPressEnter?: LayerProps['onKeyPress'];
    open?: boolean;
  };

type ModalStaticProps = {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
};

/**
 * Layered dialog component for displaying content in a backdropped window,
 * above the page content.
 *
 * ```typescript
 * <Modal
 *   open={isOpen}
 *   onPressEnter={onPressEnter}
 *   onClickOutside={onClickOutside}
 *   onEsc={onPressEsc}
 * >
 *   <Modal.Header>...</Modal.Header>
 *   <Modal.Body>...</Modal.Body>
 *   <Modal.Footer>...</Modal.Footer>
 * </Modal>
 * ```
 *
 * See the Storybook on this component for more examples.
 */
export const Modal: ModalStaticProps & React.FC<ModalProps> = ({
  children,
  className,
  onClickOutside,
  onEsc,
  onPressEnter,
  open,
  position = 'center',
  ...boxProps
}) => {
  if (open === false) {
    return null;
  }

  const onKeyPress: LayerProps['onKeyPress'] = e => {
    if (e.key === 'Enter' && typeof onPressEnter === 'function') {
      onPressEnter(e);
    }
  };

  return (
    <Layer
      className={className}
      full={position === 'left' || position === 'right' ? 'vertical' : false}
      onClickOutside={onClickOutside}
      onEsc={onEsc}
      onKeyPress={onKeyPress}
      position={position}
    >
      {position === 'center' && (
        <Area gap="small" pad="medium" width={{ min: 'medium' }} {...boxProps}>
          {children}
        </Area>
      )}

      {(position === 'left' || position === 'right') && (
        <Box fill="vertical" width="medium" {...boxProps}>
          {children}
        </Box>
      )}
    </Layer>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

import { PropsOf } from '@voleer/types';
import {
  Box,
  CheckBoxProps,
  ThemeType,
  base as grommetBaseTheme,
} from 'grommet';
import { deepMerge, normalizeColor } from 'grommet/utils';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { css } from 'styled-components';
import { scaleBy } from '../utils/styles';

const fontFamily = 'TT Norms Pro, sans-serif';
const baseFontSize = '10px';
const bodyFontSize = scaleBy(1.4, baseFontSize);
const disabledOpacity = 0.3;
const textInputHeight = '36px';

export type VoleerTheme = ThemeType & {
  global?: {
    colors?: {
      'status-ok-light'?: string;
      'status-info'?: string;

      // Default color for all kinds of box borders.
      'border-default'?: string;
    };
  };
};

const themeExtensions: VoleerTheme = {
  global: {
    breakpoints: {
      xsmall: {
        value: 500,
      },
      small: {
        value: 800,
      },
      medium: {
        value: 1200,
      },
    },
    colors: {
      // Brand
      brand: '#3b5ed8',

      // Default color for borders
      border: 'light-5',

      // Secondary
      'accent-1': '#c145a1',
      'accent-2': '#ff9c5e',
      'accent-3': '#ffd559',
      'accent-4': '#1ec7d0',
      'neutral-1': '#ced5f6',
      'neutral-2': '#9baded',
      'neutral-3': '#6b84e5',
      'neutral-4': '#354199',
      'neutral-5': '#2f2459',

      // Greys
      white: '#ffffff',
      'light-1': '#f8f8f9',
      'light-2': '#f3f3f3',
      'light-3': '#eeeeee',
      'light-4': '#dcdbde',
      black: '#18191c',
      'dark-1': '#2f3137',
      'dark-2': '#595862',
      'dark-3': '#86858e',
      'dark-4': '#cac8ce',
      background: 'light-2',

      // Status
      'status-error': '#ed4777',
      'status-info': '#ced7f5',
      'status-warning': '#ffb95c',
      'status-ok': '#90d44e',
      'status-unknown': '#dcdbde',
      'status-error-light': '#f8b5c9',
      'status-error-darker': '#c81955',
      'status-error-darkest': '#910535',
      'status-warning-light': '#ffd8a5',
      'status-ok-light': '#c8eaa7',
      'status-processing-light': '#ced5f6',

      // Full set of supported colors
      'ruby-red': '#ed4777',
      red: '#ff5959',
      'dark-orange': '#ff7b5c',
      'light-orange': '#ffb95c',
      'light-green': '#c8d554',
      green: '#90d44e',
      'dark-green': '#57ce8f',
      'light-blue': '#03afff',
      blue: '#1c8df3',
      'dark-blue': '#346ae7',

      // Input/Button
      focus: '#ced7f5',
    },
    drop: {
      border: { radius: '4px' },
      // TODO remove once Grommet implements fix for issue https://github.com/grommet/grommet/issues/5617
      // @ts-ignore Grommet's type interfaces are not up to date.
      extend: css`
        &,
        &:focus {
          box-shadow: ${props => props.theme.global.elevation.light.large};
        }
      `,
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '0 0 8px rgba(53, 65, 153, 0.05)',
        large: '0 0 12px rgba(47, 36, 89, 0.2)',
      },
      dark: {
        none: 'none',
        xsmall: '0 0 8px rgba(53, 65, 153, 0.05)',
        large: '0 0 12px rgba(47, 36, 89, 0.2)',
      },
    },
    font: {
      family: fontFamily,
      size: bodyFontSize,
    },
    input: {
      font: {
        weight: 450,
      },
    },
    selected: {
      background: 'neutral-1',
      color: 'black',
    },
  },
  text: {
    xsmall: { size: '12px' },
    small: { size: '14px' },
    medium: { size: '15px' },
    large: { size: '16px' },
    xlarge: { size: '18px' },
    xxlarge: { size: '22px' },
  },
  paragraph: {
    small: {
      size: '14px',
      height: '17px',
    },
    medium: {
      size: '15px',
      height: '18px',
    },
    large: {
      size: '16px',
      height: '19px',
    },
    xlarge: {
      size: '18px',
      height: '21px',
    },
    xxlarge: {
      size: '22px',
      height: '26px',
    },
    extend: props => {
      const paragraphColor = normalizeColor('dark-1', props.theme);
      return css`
        color: ${paragraphColor};
        font-family: ${fontFamily};
        max-width: 100%;
      `;
    },
  },
  heading: {
    level: {
      '1': {
        font: {
          weight: 700,
          family: 'TT Norms Pro Bold, sans-serif',
        },
        small: {
          size: '44px',
          height: '52px',
          maxWidth: '100%',
        },
        medium: {
          size: '60px',
          height: '71px',
          maxWidth: '100%',
        },
        large: {
          size: '81px',
          height: '96px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '91px',
          height: '108px',
          maxWidth: '100%',
        },
      },
      '2': {
        font: {
          weight: 700,
          family: 'TT Norms Pro Bold, sans-serif',
        },
        small: {
          size: '33px',
          height: '39px',
          maxWidth: '100%',
        },
        medium: {
          size: '42px',
          height: '50px',
          maxWidth: '100%',
        },
        large: {
          size: '54px',
          height: '64px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '61px',
          height: '72px',
          maxWidth: '100%',
        },
      },
      '3': {
        font: {
          weight: 600,
          family: 'TT Norms Pro Demi Bold, sans-serif',
        },
        small: {
          size: '25px',
          height: '29px',
          maxWidth: '100%',
        },
        medium: {
          size: '30px',
          height: '36px',
          maxWidth: '100%',
        },
        large: {
          size: '36px',
          height: '43px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '41px',
          height: '48px',
          maxWidth: '100%',
        },
      },
      '4': {
        font: {
          weight: 600,
          family: 'TT Norms Pro Demi Bold, sans-serif',
        },
        small: {
          size: '19px',
          height: '29px',
          maxWidth: '100%',
        },
        medium: {
          size: '21px',
          height: '25px',
          maxWidth: '100%',
        },
        large: {
          size: '24px',
          height: '28px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '27px',
          height: '32px',
          maxWidth: '100%',
        },
      },
    },
  },
  textArea: {
    extend: props => {
      const backgroundColor = normalizeColor('light-2', props.theme);
      const borderColor = normalizeColor('dark-4', props.theme);
      const disabledBackgroundColor = normalizeColor('light-1', props.theme);
      const disabledFontColor = normalizeColor('light-4', props.theme);
      const focusBorderColor = normalizeColor('neutral-3', props.theme);
      const fontColor = normalizeColor('black', props.theme);
      const placeholderColor = normalizeColor('dark-3', props.theme);

      return css`
        background-color: ${props.disabled
          ? disabledBackgroundColor
          : backgroundColor};
        border: ${props.disabled ? 0 : '1px'} solid ${borderColor};
        color: ${props.disabled ? disabledFontColor : fontColor};
        font-size: 16px;
        opacity: 1;

        &:focus {
          border-color: ${focusBorderColor};
          box-shadow: none;
        }

        &::placeholder {
          color: ${props.disabled ? disabledFontColor : placeholderColor};
        }

        // Hide the resize control that WebKit browsers automatically add to
        // the bottom right of textareas, for consistency across all browsers.
        // https://davidwalsh.name/style-textarea-resizer
        ::-webkit-resizer {
          display: none;
        }
      `;
    },
  },
  textInput: {
    extend: props => {
      const backgroundColor = normalizeColor('light-2', props.theme);
      const borderColor = normalizeColor('dark-4', props.theme);
      const disabledBackgroundColor = normalizeColor('light-1', props.theme);
      const disabledFontColor = normalizeColor('light-4', props.theme);
      const focusBorderColor = normalizeColor('neutral-3', props.theme);
      const fontColor = normalizeColor('black', props.theme);
      const placeholderColor = normalizeColor('dark-3', props.theme);

      return css`
        background-color: ${props.disabled
          ? disabledBackgroundColor
          : backgroundColor};
        border: ${props.disabled ? 0 : '1px'} solid ${borderColor};
        color: ${props.disabled ? disabledFontColor : fontColor};
        font-size: 16px;
        opacity: 1;

        &:focus {
          border-color: ${focusBorderColor};
          box-shadow: none;
        }

        &::placeholder {
          color: ${props.disabled ? disabledFontColor : placeholderColor};
        }
      `;
    },
  },
  tab: {
    active: {
      color: 'brand',
    },
    color: 'dark-2',
    margin: {
      vertical: 'none',
      horizontal: 'none',
    },
    border: {
      size: 'medium',
      color: 'transparent',
      active: {
        color: 'brand',
      },
      hover: {
        color: 'background',
      },
    },
  },
  tabs: {
    gap: 'medium',
    header: {
      // TODO (5004): opened Grommet issue for setting header margin
      // https://github.com/grommet/grommet/issues/5647
      border: {
        color: 'transparent',
        size: 'xlarge',
      },
      extend: css`
        border-top: none;
        border-bottom: none;
      `,
    },
    panel: {
      extend: css`
        overflow: 'auto';
      `,
    },
  },
  button: {
    active: {
      default: {
        background: { color: 'brand', opacity: 0.2 },
        border: { color: 'brand' },
        color: 'brand',
      },
      primary: {
        background: { color: 'neutral-5' },
        border: { color: 'neutral-5' },
        color: 'white',
      },
    },
    border: {
      radius: '4px',
    },
    default: {
      background: {
        color: 'white',
      },
      border: {
        color: 'brand',
        width: '2px',
      },
      color: 'brand',
      extend: props => {
        return css`
          ${props.disabled &&
          `
            background-color: ${normalizeColor('white', props.theme)};
            border-color: ${normalizeColor('light-4', props.theme)};
            color: ${normalizeColor('light-4', props.theme)};

            // Button icon
            svg {
              fill: ${normalizeColor('light-4', props.theme)};
            }`}
        `;
      },
      font: { weight: '600' },
    },
    disabled: {
      opacity: 1,
    },
    extend: props => {
      return css`
        line-height: 16px;

        // icon-only logic: verifies there is only child and that the child is not
        // a string, which would imply that it is a string for the button label.
        // TODO (5003): Update logic after Grommet issue is resolved
        // https://github.com/grommet/grommet/issues/5613
        ${!!props.children &&
        typeof props.children !== 'string' &&
        !props.children.props.children &&
        css`
          line-height: 0;
          padding: ${props.sizeProp === 'small' ? '8px' : '12px'};
        `}
      `;
    },
    hover: {
      default: {
        background: { color: 'brand', opacity: 0.1 },
        border: { color: 'brand' },
        color: 'brand',
      },
      primary: {
        background: { color: 'neutral-4' },
        border: { color: 'neutral-4' },
        color: 'white',
      },
    },
    size: {
      medium: {
        border: { radius: '4px' },
        pad: { vertical: '12px', horizontal: '24px' },
      },
      small: {
        border: { radius: '4px' },
        pad: { vertical: '8px', horizontal: '16px' },
      },
    },
    primary: {
      background: { color: 'brand' },
      border: { color: 'brand', width: '2px' },
      color: 'white',
      extend: props => {
        return css`
          ${props.disabled &&
          `
            background-color: ${normalizeColor('light-4', props.theme)};
            border-color: ${normalizeColor('light-4', props.theme)};
            color: ${normalizeColor('light-1', props.theme)};

            // Button icon
            svg {
              fill: ${normalizeColor('white', props.theme)};
            }
          `}
        `;
      },
      font: { weight: '600' },
    },
  },
  accordion: {
    border: {
      color: 'neutral-1',
      size: 'small',
    },
    icons: {
      color: 'brand',
      collapse: FaChevronUp,
      expand: FaChevronDown,
    },
  },
  icon: {
    size: {
      small: '12px',
      medium: '16px',
      large: '24px',
      xlarge: '32px',
      xxlarge: '48px',
    },
  },
  box: {
    extend: css`
      cursor: ${(props: PropsOf<typeof Box>) =>
        props.onClick ? 'pointer' : 'inherit'};
    `,
  },
  formField: {
    border: false,
    error: {
      color: 'status-error',
      margin: {
        horizontal: '0',
        top: '0',
      },
    },
    label: {
      margin: {
        left: '0',
        bottom: '2px',
      },
    },
  },
  radioButton: {
    border: {
      color: 'dark-4',
      width: '1px',
    },
    check: {
      background: {
        // TODO: @tdunlop to change to 'light-2' once
        // Grommet fix is implemented for issue #5618.
        color: '#F3F3F3',
      },
    },
    hover: {
      border: {
        color: 'brand',
      },
    },
    icon: {
      extend: props => css`
        border-radius: 100%;
        fill: ${normalizeColor('white', props.theme)};
        background: ${normalizeColor('brand', props.theme)};
      `,
    },
  },
  checkBox: {
    border: {
      color: 'light-2',
    },
    extend: props => {
      return css`
        // Exclude these extended checkbox styles from the checkbox toggle variant
        // https://storybook.grommet.io/?path=/story/input-checkbox-toggle--toggle
        ${!props.toggle &&
        `
            svg {
              stroke: ${normalizeColor(
                props.checked ? 'white' : 'none',
                props.theme
              )};
              background-color: ${normalizeColor(
                props.disabled ? 'dark-4' : 'brand',
                props.theme
              )};
              border-radius: 4px;
            }
            div {
              border-width: 1px;
              background-color: ${
                props.checked
                  ? normalizeColor('brand', props.theme)
                  : normalizeColor(
                      props.disabled ? 'light-1' : 'light-2',
                      props.theme
                    )
              };
              border-color: ${
                props.checked
                  ? normalizeColor(
                      props.disabled ? 'light-4' : 'brand',
                      props.theme
                    )
                  : normalizeColor(
                      props.disabled ? 'light-1' : 'dark-4',
                      props.theme
                    )
              };
              border-radius: 4px;
            }

            // Default styles for the text rendered next to the Checkbox, when the label prop is a string
            span {
              color: ${props.disabled ? 'light-4' : 'dark-1'};
            }
          `}
      `;
    },
    hover: {
      border: {
        color: 'neutral-1',
      },
    },
    color: 'white',
    toggle: {
      size: '40px',
      color: {
        light: 'white',
      },
      extend: props => css`
        background-color: ${props.checked
          ? normalizeColor('brand', props.theme)
          : normalizeColor('light-4', props.theme)};
      `,
      knob: {
        extend: css`
          color: ${props => normalizeColor('white', props.theme)};
          height: 16px;
          left: ${(props: CheckBoxProps) =>
            props.checked ? '18px !important' : '2px'};
          top: 2px;
          width: 16px;
        `,
      },
    },
  },
  maskedInput: {
    extend: props => css`
      padding: ${props.theme.global?.edgeSize?.small};
      height: ${textInputHeight};

      ${props.disabled && `opacity: ${disabledOpacity};`}
    `,
  },
  select: {
    clear: {
      container: {
        background: 'transparent',
      },
      text: { color: 'black' },
    },
    container: {
      extend: props => css`
        padding: ${props.theme.global?.edgeSize?.xsmall};

        [role='menubar']:focus {
          box-shadow: none;
        }

        button[role='menuitem'] > div {
          border-radius: 4px;
        }

        button[role='menuitem']:hover {
          background-color: ${normalizeColor(
            'light-2',
            props.theme
          )} !important;
          border-radius: 4px;
        }

        // Truncating option text here since we are rendering custom Select children as options
        // https://v2.grommet.io/select#children
        span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        @media screen and (min-width: ${props.theme.global?.breakpoints?.small
            ?.value}) {
          max-width: 300px;
        }
      `,
    },
    options: {
      container: {
        pad: 'small',
      },
      text: { color: 'black' },
    },
    control: {
      extend: props => {
        return css`
          background-color: ${normalizeColor(
            props.disabled ? 'light-1' : 'light-2',
            props.theme
          )};
          border-color: ${normalizeColor(
            // default: dark-4, open: neutral-3, disabled: light-2
            props.open ? 'neutral-3' : props.disabled ? 'light-2' : 'dark-4',
            props.theme
          )};
          border-radius: 4px;
          border-style: solid;
          border-width: 1px;
          overflow: hidden;

          svg {
            color: ${normalizeColor(
              // default: dark-4, open: neutral-2, disabled: light-4
              props.open ? 'neutral-2' : props.disabled ? 'light-4' : 'dark-4',
              props.theme
            )} !important;
          }

          input {
            // Disable pointer events to ensure that the input doesn't receive a
            // cursor position when clicking it to open the select menu. Otherwise
            // when the value changes the text appears right-aligned in the select
            // in Firefox or when using a Formik onChange handler.
            // Fixes: https://bittitan.visualstudio.com/Voleer/_workitems/edit/1703/
            pointer-events: none;

            // Prevent the textInput extended styles from applying to the select extended styles
            &[type='text'] {
              background: transparent;
              border: none;
            }

            &::placeholder {
              color: ${normalizeColor(
                props.disabled ? 'light-4' : 'dark-3',
                props.theme
              )};
            }

            ${props.disabled &&
            `color: ${normalizeColor('light-4', props.theme)}`}
          }
        `;
      },
    },
    icons: {
      up: FaChevronUp,
      down: FaChevronDown,
    },
  },
  menu: {
    drop: {
      align: { top: 'bottom', left: 'left' },
    },
  },
};

export const voleer2: VoleerTheme = deepMerge<
  VoleerTheme,
  [ThemeType, VoleerTheme]
>({}, grommetBaseTheme, themeExtensions);

// Type representing a named text size available in the theme
const voleerTextSizes = voleer2.text;
export type VoleerTextSize = keyof NonNullable<typeof voleerTextSizes>;

// Type representing a named icon size available in the theme
const voleerIconSizes = voleer2.icon?.size;
export type VoleerIconSize = keyof NonNullable<typeof voleerIconSizes>;

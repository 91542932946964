import { throwErrors } from '@voleer/io-ts';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { isBrowser, isTest } from './utils/environment';

export const AppConfigT = t.type(
  {
    USE_GRAPHQL_PLAYGROUND: t.union([t.literal('true'), t.literal('false')]),

    AUTH_API_URL: t.string,
    AUTH_API_VERSION: t.string,

    TENANTS_API_URL: t.string,
    TENANTS_API_VERSION: t.string,

    ROOT_DOMAIN: t.string,
    TENANT_OID_AUTHORIZE_URL: t.string,

    GOOGLE_TAG_MANAGER_ID: t.union([t.string, t.undefined]),
    DATADOG_CLIENT_TOKEN: t.union([t.string, t.undefined]),
    PENDO_API_KEY: t.union([t.string, t.undefined]),
    PENDO_SUB_ID: t.union([t.string, t.undefined]),
  },
  'AppConfig'
);

export type AppConfig = t.TypeOf<typeof AppConfigT>;

// Builds an app config for use on the client/browser
const getClientConfig = (): AppConfig => {
  const decoded = AppConfigT.decode({
    USE_GRAPHQL_PLAYGROUND: window.env.USE_GRAPHQL_PLAYGROUND,

    AUTH_API_URL: window.env.AUTH_API_URL,
    AUTH_API_VERSION: window.env.AUTH_API_VERSION,

    TENANTS_API_URL: window.env.TENANTS_API_URL,
    TENANTS_API_VERSION: window.env.TENANTS_API_VERSION,

    ROOT_DOMAIN: window.env.ROOT_DOMAIN,
    TENANT_OID_AUTHORIZE_URL: window.env.TENANT_OID_AUTHORIZE_URL,

    GOOGLE_TAG_MANAGER_ID: window.env.GOOGLE_TAG_MANAGER_ID,
    DATADOG_CLIENT_TOKEN: window.env.DATADOG_CLIENT_TOKEN,
    PENDO_API_KEY: window.env.PENDO_API_KEY,
    PENDO_SUB_ID: window.env.PENDO_SUB_ID,
  });

  return pipe(decoded, E.getOrElseW(throwErrors));
};

// Builds an app config for use on the server
const getServerConfig = (): AppConfig => {
  const decoded = AppConfigT.decode({
    USE_GRAPHQL_PLAYGROUND:
      process.env.ARK_USE_GRAPHQL_PLAYGROUND ||
      process.env.RAZZLE_USE_GRAPHQL_PLAYGROUND,

    AUTH_API_URL:
      process.env.ARK_AUTH_API_URL || process.env.RAZZLE_AUTH_API_URL,
    AUTH_API_VERSION:
      process.env.ARK_AUTH_API_VERSION || process.env.RAZZLE_AUTH_API_VERSION,

    TENANTS_API_URL:
      process.env.ARK_TENANTS_API_URL || process.env.RAZZLE_TENANTS_API_URL,
    TENANTS_API_VERSION:
      process.env.ARK_TENANTS_API_VERSION ||
      process.env.RAZZLE_TENANTS_API_VERSION,

    ROOT_DOMAIN: process.env.ARK_ROOT_DOMAIN || process.env.RAZZLE_ROOT_DOMAIN,
    TENANT_OID_AUTHORIZE_URL:
      process.env.ARK_TENANT_OID_AUTHORIZE_URL ||
      process.env.RAZZLE_TENANT_OID_AUTHORIZE_URL,

    GOOGLE_TAG_MANAGER_ID:
      process.env.ARK_GOOGLE_TAG_MANAGER_ID ||
      process.env.RAZZLE_GOOGLE_TAG_MANAGER_ID,
    DATADOG_CLIENT_TOKEN:
      process.env.ARK_DATADOG_CLIENT_TOKEN ||
      process.env.RAZZLE_DATADOG_CLIENT_TOKEN,
    PENDO_API_KEY:
      process.env.ARK_PENDO_API_KEY || process.env.RAZZLE_PENDO_API_KEY,
    PENDO_SUB_ID:
      process.env.ARK_PENDO_SUB_ID || process.env.RAZZLE_PENDO_SUB_ID,
  });

  return pipe(decoded, E.getOrElseW(throwErrors));
};

/**
 * Retrieves the current application configuration.
 */
export const getAppConfig = () => {
  if (isTest()) {
    return getServerConfig(); // Just re-use the .env config in test mode for now
  } else if (
    typeof window !== 'undefined' &&
    window &&
    window.env &&
    isBrowser()
  ) {
    return getClientConfig();
  }

  return getServerConfig();
};

export const appConfig: AppConfig = getAppConfig();


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Integration": [
      "CustomWorkflowIntegration",
      "OAuth2Integration"
    ],
    "Notification": [
      "RunNotification"
    ],
    "NotificationSubscription": [
      "RunNotificationSubscription"
    ]
  }
};
      export default result;
    
import React from 'react';
import { PropsOf } from '@voleer/types';
import { Box } from 'grommet';
import styled from 'styled-components';

const Container = styled(Box)`
  ${props => {
    return `
      @media only screen and (min-width: ${
        props.theme.global.breakpoints.xsmall.value + 1
      }px) {
        padding: 0 ${props.theme.global.edgeSize.small};
      }
    
      width: 100%;
  `;
  }}
`;

/**
 * Renders a responsive page container. Expands to fill the entire width
 * of the viewport on small screens.
 */
export const PageContainer: React.FC<PropsOf<typeof Box>> = props => {
  return (
    <Container
      margin={{ bottom: 'medium', horizontal: 'auto' }}
      width={{ max: '1200px' }}
      {...props}
    />
  );
};

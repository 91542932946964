/**
 * An error indicating that the current path should never be reached.
 *
 * The benefit of using this over a standard error is that TypeScript will not
 * compile if finds that the type passed to this error has not been narrowed to
 * never. This way you can catch unhandled cases at compile time.
 *
 * It's often helpful in ensuring all cases have been exhausted when
 * disambiguating a discriminated union type:
 *
 * ```
 * // Given a tagged union type
 * type MyObj = {
 *   kind: 'First' | 'Second'
 * }
 *
 * // Then, given that `someObj` is of type `MyObj`
 * switch(someObj.kind) {
 *   case 'First':
 *     return ...;
 *   case 'Second':
 *     return ...;
 *   default: // This case should never be executed
 *     throw new UnreachableCaseError(someObj);
 * }
 *
 * // If you don't handle one of the cases:
 * switch(someObj.kind) {
 *   case 'First':
 *     return ...;
 *   default: // TypeScript complains because the 'Second' case was not handled
 *     throw new UnreachableCaseError(someObj);
 * }
 * ```
 */
export class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`);
  }
}

type EnsureUnreachableOptions = {
  /**
   * Throw an `UnreachableCaseError` if called. Default is `false`.
   */
  throw?: boolean;
};

/**
 * Ensures that the given `value` is considered unreachable by TypeScript.
 * Unlike throwing `UnreachableCaseError`, by default this will not throw an
 * error if called at run time.
 */
export const ensureUnreachable = (
  value: never,
  options: EnsureUnreachableOptions = {
    throw: false,
  }
) => {
  if (options.throw) {
    throw new UnreachableCaseError(value);
  }
};

import React from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

export type SortDirection = 'asc' | 'desc';

export type SortDirectionCaretProps = {
  direction: SortDirection;
};

/**
 * Component for displaying a directional caret icon based on the provided direction.
 */
export const SortDirectionCaret: React.FC<SortDirectionCaretProps> = ({
  direction,
}) => {
  return direction === 'asc' ? <FaCaretDown /> : <FaCaretUp />;
};

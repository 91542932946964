import { normalizeColor } from 'grommet/utils';
import styled, { css } from 'styled-components';
import { LoadingButton } from '../LoadingButton';

/**
 * Renders a <LoadingButton> with status-error styling.
 */
export const ErrorButton = styled(LoadingButton)`
  background-color: ${props => normalizeColor('status-error', props.theme)};
  border-color: ${props => normalizeColor('status-error', props.theme)};
  color: ${props => normalizeColor('light-1', props.theme)};

  svg {
    fill: ${props => normalizeColor('light-1', props.theme)};
  }

  &:hover {
    background-color: ${props =>
      normalizeColor('status-error-darker', props.theme)};
    border-color: ${props =>
      normalizeColor('status-error-darker', props.theme)};
  }

  ${props =>
    props.active &&
    `
      background-color: 
        ${normalizeColor('status-error-darkest', props.theme)};
      border-color: ${normalizeColor('status-error-darkest', props.theme)};
    `}

  ${props =>
    props.disabled || props.loading
      ? css`
          &,
          &:hover {
            background-color: ${props =>
              normalizeColor('light-4', props.theme)};
            border-color: ${props => normalizeColor('light-4', props.theme)};
            color: ${props => normalizeColor('light-1', props.theme)};

            svg {
              fill: ${props => normalizeColor('light-1', props.theme)};
            }
          }
        `
      : ''}
`;

import React, {
  createContext,
  isValidElement,
  useContext,
  useRef,
  useState,
} from 'react';
import { PropsOf, isElementOf, isPromise } from '@voleer/types';
import { Box, Text, TextInput } from 'grommet';
import { WidthType, deepMerge, normalizeColor } from 'grommet/utils';
import { range } from 'lodash';
import { FaSearch } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { Area, Icon, LoadingButton, LoadingButtonProps } from '..';
import { usePreserveScrollPosition } from '../..';
import {
  SortDirectionCaret,
  SortDirectionCaretProps,
} from '../SortDirectionCaret';

type AreaProps = PropsOf<typeof Area>;

type ColumnProps = AreaProps;

type RowProps = {
  /**
   * The number of rows expected to be in the table.
   *
   * Setting this allows the table to render an appropriate number of rows even
   * when the actual row content has not yet been provided.
   */
  count?: number;
};

type FancyTableContextValue = {
  /**
   * Renders the table in a "disabled" state.
   */
  disabled: boolean;

  /**
   * Renders the table in a "loading" state.
   */
  loading: boolean;

  /**
   * An array of `Area` props to be passed to each column. Useful for setting
   * column widths and other attributes.
   */
  columns: Array<ColumnProps | undefined>;

  /**
   * Provide information about the rows to the table.
   */
  rows: RowProps;
};

const DEFAULT_ROW_PADDING: TableRowProps['pad'] = {
  horizontal: 'medium',
  vertical: 'small',
};

/**
 * Context for shared table state.
 */
const FancyTableContext = createContext<FancyTableContextValue>({
  disabled: false,
  loading: false,
  columns: [],
  rows: {},
});

const useFancyTable = () => useContext(FancyTableContext);

type TableHeaderProps = PropsOf<typeof Area>;

const TABLE_TOOLBAR_HEIGHT = '61px';
const TABLE_STICKY_ELEMENT_Z_INDEX = 1;

const StyledTableHeader = styled(Area)`
  position: sticky;
  top: 0;
  z-index: ${TABLE_STICKY_ELEMENT_Z_INDEX}; // Stack above the table body when scrolling

  // Show the header below the toolbar when the toolbar is present
  .table-toolbar ~ & {
    top: ${TABLE_TOOLBAR_HEIGHT};
  }
`;

/**
 * Defines the header section of a `FancyTable`, similar to `thead`.
 */
const TableHeader: React.FC<TableHeaderProps> = props => {
  const defaultProps: AreaProps = {
    flex: { grow: 0, shrink: 0 },
  };
  return (
    <StyledTableHeader
      background="light-2"
      className="table-header"
      {...deepMerge(defaultProps, props)}
    />
  );
};

type TableBodyProps = PropsOf<typeof Area>;

/**
 * Designates the body section of a `FancyTable`, similar to `tbody`.
 */
const TableBody: React.FC<TableBodyProps> = ({ children, ...props }) => {
  const { loading } = useFancyTable();

  const defaultProps: AreaProps = {
    flex: { grow: 1, shrink: 0 },
  };

  return (
    <Area className="table-body" {...deepMerge(defaultProps, props)}>
      {loading ? <TableLoadingSkeleton /> : children}
    </Area>
  );
};

type TableRowProps = AreaProps;

/**
 * Gets the flex basis for a table cell based on its width definition.
 */
const getCellBasis = (width?: WidthType): string | undefined => {
  if (typeof width === 'undefined') {
    return undefined;
  }

  if (typeof width === 'string') {
    return width;
  }

  return width.width;
};

/**
 * Designates a row in a `FancyTable`, similar to a `tr`.
 */
const TableRow: React.FC<TableRowProps> = ({ children, ...props }) => {
  const ctx = useFancyTable();
  const columns = ctx.columns || [];

  let cellCount = 0;

  // Augment cell children with additional properties
  children = React.Children.map(children, child => {
    if (!isValidElement(child)) {
      return child;
    }

    const isCell =
      isElementOf(TableCell, child) ||
      isElementOf(TableHeaderCell, child) ||
      isElementOf(LoadingSkeletonCell, child);

    if (isCell) {
      const index = cellCount;
      cellCount = cellCount + 1;
      const column = columns[index];

      const width: typeof child.props.width =
        child.props.width ||
        (typeof column === 'object' ? column.width : column);

      const defaultChildProps: TableCellProps | TableHeaderProps = {
        width,
        basis: getCellBasis(width) || '0',
        flex: width ? { grow: 0, shrink: 0 } : { grow: 1, shrink: 0 },
        ...(typeof column === 'object' ? column : undefined),
      };

      return React.cloneElement(
        child,
        deepMerge(defaultChildProps, child.props)
      );
    }

    // Return the original, unaltered child by default
    return child;
  });

  const defaultProps: AreaProps = {
    align: 'center',
    direction: 'row',
    flex: { grow: 1, shrink: 0 },
    gap: 'small',
    pad: DEFAULT_ROW_PADDING,
  };

  return <Area {...deepMerge(defaultProps, props)}>{children}</Area>;
};

type TableCellProps = AreaProps;

/**
 * Renders a cell of a `FancyTable`, similar to `td`.
 */
const TableCell: React.FC<TableCellProps> = props => {
  return <Area className="table-cell" direction="row" {...props} />;
};

type TableHeaderCellProps = TableCellProps & {
  sortDirection?: SortDirectionCaretProps['direction'];
};

/**
 * Renders a cell in the header of a `FancyTable`, similar to `th`.
 */
const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  children,
  sortDirection,
  onClick,
  ...props
}) => {
  const { disabled } = useFancyTable();
  return (
    <Area
      className="table-cell"
      direction="row"
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      {...props}
    >
      <Text color="dark-2">{children}</Text>

      {sortDirection && (
        <Box>
          <Text color="dark-2">
            <SortDirectionCaret direction={sortDirection} />
          </Text>
        </Box>
      )}
    </Area>
  );
};

const loadingSkeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

/**
 * Renders a cell for the loading skeleton table layout.
 */
const LoadingSkeletonCell = styled(TableCell)`
  background-color: ${props => normalizeColor('light-1', props.theme)};
  background-image: linear-gradient(
    90deg,
    ${props => normalizeColor('light-1', props.theme)},
    ${props => normalizeColor('background', props.theme)},
    ${props => normalizeColor('light-1', props.theme)}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: ${loadingSkeletonKeyframes} 1s ease-in-out infinite;
`;

/**
 * Renders the loading state/skeleton for the table.
 */
const TableLoadingSkeleton: React.FC = () => {
  const { columns, rows } = useFancyTable();
  const columnCount = columns.length || 3;
  const rowsCount = rows.count || 3;

  const cells = range(0, columnCount).map(count => (
    <LoadingSkeletonCell fill={true} key={`skeleton-cell-${count}`}>
      {/* Insert a zero-width character as an easy way to make sure the height
      of the empty cell is determined by the line-height of the font */}
      &zwnj;
    </LoadingSkeletonCell>
  ));

  return (
    <>
      {range(0, rowsCount).map(count => (
        <TableRow key={`skeleton-row-${count}`}>{cells}</TableRow>
      ))}
    </>
  );
};
type TableToolbarProps = AreaProps;

const StyledTableToolbar = styled(Area)`
  position: sticky;
  top: 0;
  z-index: ${TABLE_STICKY_ELEMENT_Z_INDEX}; // Stack above the table body when scrolling
`;

/**
 * Renders a toolbar area for the table.
 */
const TableToolbar: React.FC<TableToolbarProps> = props => {
  const defaultProps: AreaProps = {
    align: 'center',
    background: 'white',
    direction: 'row',
    flex: { grow: 0, shrink: 0 },
    height: TABLE_TOOLBAR_HEIGHT,
    pad: DEFAULT_ROW_PADDING,
    gap: 'small',
  };
  return (
    <StyledTableToolbar
      className="table-toolbar"
      {...deepMerge(defaultProps, props)}
    />
  );
};

type TableSearchProps = Pick<
  PropsOf<typeof TextInput>,
  'onChange' | 'placeholder' | 'value'
>;

/**
 * Renders the search input area for the table.
 */
const TableSearch: React.FC<TableSearchProps> = ({ value, ...props }) => {
  const { disabled } = useFancyTable();
  return (
    <Area align="center" direction="row" flex={{ grow: 1 }} gap="small">
      <Icon icon={FaSearch} />
      <Box flex={{ grow: 1 }}>
        <TextInput
          disabled={disabled}
          plain={true}
          value={value || ''}
          {...props}
        />
      </Box>
    </Area>
  );
};

type TableEmptyProps = AreaProps;

/**
 * Renders a container for the empty state of the table.
 */
const TableEmpty: React.FC<TableEmptyProps> = ({ children, ...props }) => {
  const defaultProps: AreaProps = {
    pad: DEFAULT_ROW_PADDING,
  };
  return <Area {...deepMerge(defaultProps, props)}>{children}</Area>;
};

type TableLoadMoreProps = Pick<AreaProps, 'pad'> &
  Pick<LoadingButtonProps, 'label'> & {
    onLoadMore: () => Promise<void> | void;
  };

/**
 * Renders a row with a button to load more records in a table that supports
 * "load more" style pagination.
 */
const TableLoadMore: React.FC<TableLoadMoreProps> = ({
  pad,
  onLoadMore,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (loading) {
      return;
    }

    const result = onLoadMore();

    if (isPromise(result)) {
      setLoading(true);
      await result;
      setLoading(false);
    }
  };

  return (
    <Area align="center" flex={{ shrink: 0 }} pad={pad || DEFAULT_ROW_PADDING}>
      <LoadingButton loading={loading} {...buttonProps} onClick={onClick} />
    </Area>
  );
};

export type FancyTableProps = PropsOf<typeof Area> & {
  disabled?: boolean;
  loading?: boolean;
  columns?: FancyTableContextValue['columns'];
  rows?: FancyTableContextValue['rows'];
};

type FancyTableStaticProps = {
  /**
   * Renders a cell in a table row.
   */
  Cell: typeof TableCell;

  /**
   * Renders a cell in the table header row.
   */
  HeaderCell: typeof TableHeaderCell;

  /**
   * Renders a table row which should contain cells.
   */
  Row: typeof TableRow;

  /**
   * Renders the body section of the table which should contain rows.
   */
  Body: typeof TableBody;

  /**
   * Renders the header section of the table, which should contain a single row
   * with column headers.
   */
  Header: typeof TableHeader;

  /**
   * Renders a search input area for the table. Should be placed inside the
   * toolbar.
   */
  Search: typeof TableSearch;

  /**
   * Renders a toolbar area for the table which can contain search, buttons,
   * and other controls for the table.
   */
  Toolbar: typeof TableToolbar;

  /**
   * Renders a container for content indicating that the table is empty.
   */
  Empty: typeof TableEmpty;

  /**
   * Renders a row with a button to load more records in a table that supports
   * "load more" style pagination.
   */
  LoadMore: typeof TableLoadMore;
};

/**
 * Component for rendering a commonly used table/list pattern in our
 * applications. See the storybook entry for examples.
 */
export const FancyTable: FancyTableStaticProps & React.FC<FancyTableProps> = ({
  columns = [],
  rows = {},
  loading = false,
  disabled = false,
  ...props
}) => {
  const areaRef = useRef<HTMLDivElement>();
  usePreserveScrollPosition(areaRef);

  return (
    <FancyTableContext.Provider value={{ columns, rows, disabled, loading }}>
      <Area
        data-testid="fancy-table"
        overflow="auto"
        {...props}
        ref={areaRef}
      />
    </FancyTableContext.Provider>
  );
};

FancyTable.Header = TableHeader;
FancyTable.Body = TableBody;
FancyTable.Row = TableRow;
FancyTable.Cell = TableCell;
FancyTable.HeaderCell = TableHeaderCell;
FancyTable.Search = TableSearch;
FancyTable.Toolbar = TableToolbar;
FancyTable.Empty = TableEmpty;
FancyTable.LoadMore = TableLoadMore;

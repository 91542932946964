import React from 'react';
import { PropsOf } from '@voleer/types';
import { Button, ButtonProps } from 'grommet';
import { FaSpinner } from 'react-icons/fa';
import { RotatingIcon } from '..';

export type LoadingButtonProps = PropsOf<typeof Button> & {
  loading?: boolean;
  loadingLabel?: string;
  loadingIcon?: ButtonProps['icon'];
};

/**
 * Renders a Grommet `<Button>` with additional loading state UX.
 */
export const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  loadingLabel,
  loadingIcon = <RotatingIcon icon={FaSpinner} size="14px" />,
  ...props
}) => {
  const label = loading && loadingLabel ? loadingLabel : props.label;
  const disabled = loading || props.disabled;
  const icon = loading && loadingIcon ? loadingIcon : props.icon;
  return <Button {...props} disabled={disabled} icon={icon} label={label} />;
};

import React from 'react';
import { PropsOf } from '@voleer/types';
import { Box, Heading } from 'grommet';
import styled from 'styled-components';

export type AreaHeadingProps = PropsOf<typeof Box> & {
  label: string;
};

/**
 * Renders a heading for an Area that contains content.
 */
export const AreaHeading: React.FC<AreaHeadingProps> = ({
  children,
  label,
  ...props
}) => (
  <Box
    border={{ side: 'bottom', color: 'background' }}
    direction="row"
    flex={{ shrink: 0 }}
    pad="medium"
    {...props}
  >
    <Heading
      level="4"
      margin={
        children ? { left: 'none', right: 'auto', vertical: 'none' } : 'none'
      }
    >
      {label}
    </Heading>

    {children}
  </Box>
);

export type AreaContentProps = PropsOf<typeof Box>;

/**
 * Provides default styling for content in an Area.
 */
export const AreaContent: React.FC<AreaContentProps> = ({ ...props }) => (
  <Box height="100%" overflow="auto">
    <Box flex={{ shrink: 0 }} pad="medium" {...props} />
  </Box>
);

export type AreaProps = PropsOf<typeof Box> & {
  /**
   * Applies disabled styling and disables pointer-events on the Area.
   */
  disabled?: boolean;

  /**
   * Use paper styling.
   */
  paper?: boolean;
};

/**
 * Default props for "paper" styling.
 */
const PAPER_PROPS: AreaProps = {
  background: 'white',
  elevation: 'xsmall',
  round: 'xsmall',
};

const AreaComponent = React.forwardRef<HTMLDivElement, AreaProps>(
  ({ disabled, paper, ...boxProps }, ref) => {
    const defaultProps: AreaProps = {
      // Paper styling
      ...(paper ? PAPER_PROPS : {}),
    };
    return <Box {...defaultProps} {...boxProps} ref={ref} />;
  }
);

/**
 * `Area` is a specialization of Grommet's `Box` component to allow for more
 * Voleer-specific UX features.
 */
export const Area = styled(AreaComponent)`
  opacity: ${(props: AreaProps) => (props.disabled ? 0.5 : 'initial')};
  pointer-events: ${(props: AreaProps) =>
    props.disabled ? 'none' : 'initial'};
`;

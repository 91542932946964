import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // Load translation using xhr -> see /public/locales
  .use(Backend)

  // Detect user language
  .use(LanguageDetector)

  // Load i18n into react
  .use(initReactI18next)

  // Init i18next
  // For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      // Not needed for react as it escapes by default
      escapeValue: false,
    },

    // Load static t9n files with a relative path
    // https://storybook.js.org/docs/react/configure/images-and-assets#absolute-versus-relative-paths
    backend: {
      loadPath: `${process.env.PUBLIC_PATH ?? ''}locales/{{lng}}/{{ns}}.json`,
    },

    resources: window.initialI18nStore,

    // Load all languages to allow fallback fr-CA => fr => en
    load: 'all',
    react: {
      // Wait for translations to be loaded before rendering components
      useSuspense: true,
    },
  });

export default i18n;

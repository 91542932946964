import { isString } from 'util';
import { getValueAndUnit } from 'polished';

/**
 * Scales a given size value by the specified ratio.
 *
 * @param ratio the ratio to scale by
 * @param value the value to scale
 */
export const scaleBy = <TValue extends number | string>(
  ratio: number,
  value: TValue
): TValue => {
  if (isString(value)) {
    const [val, unit] = getValueAndUnit(value);
    const returnValue = Number(val) * ratio;
    return `${returnValue}${unit || ''}` as TValue;
  } else {
    return (Number(value) * ratio) as TValue;
  }
};

import { JSONObject } from '@voleer/types';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { JSONObjectT } from './json';

/**
 * io-ts codec that matches only non-empty strings (i.e. no whitespace).
 */
export const NonEmptyStringT = new t.Type<string>(
  'NonEmptyString',
  t.string.is,
  (value, ctx) => {
    return pipe(
      t.string.validate(value, ctx),
      E.chain(s => {
        if (s.trim().length === 0) {
          return t.failure(s, ctx);
        }
        return t.success(s);
      })
    );
  },
  t.identity
);

/**
 * io-ts codec that ensures a JSON string can be parsed into an object.
 */
export const ObjectFromJsonString = new t.Type<JSONObject, string, unknown>(
  'ObjectFromJsonString',
  JSONObjectT.is,
  (value, ctx) => {
    return pipe(
      t.string.validate(value, ctx),
      E.chain(s => {
        try {
          const json = JSON.parse(s);
          return t.success(json);
        } catch (e) {
          return t.failure(value, ctx, e.message);
        }
      })
    );
  },
  JSON.stringify
);

import React from 'react';
import { Link } from '@voleer/ui-kit';
import { Paragraph } from 'grommet';
import { Trans } from 'react-i18next';
import { urlFor } from '../../../../App';

type SelectSiteLinkProps = Readonly<{
  nextUrl: string | null;
  utm: string | null;
}>;

/**
 * Renders the select-site link in the registration page.
 */
export const SelectSiteLink: React.FC<SelectSiteLinkProps> = ({
  nextUrl,
  utm,
}) => {
  return (
    <Paragraph>
      <Trans i18nKey="sign-in-cta" ns="pages/RegisterPage">
        Already have an account?&nbsp;
        <Link
          data-testid="select-site-link"
          to={urlFor('selectSite')({
            nextUrl,
            utm,
          })}
          variation="primary"
        >
          Sign In
        </Link>
      </Trans>
    </Paragraph>
  );
};

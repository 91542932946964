import React from 'react';
import { Anchor } from '@voleer/ui-kit';
import { Box } from 'grommet';
import { Trans } from 'react-i18next';
import { urlFor } from '../../../App';

const MARKETING_LINKS_I18N_NS = 'components/authentication/MarketingLinks';

/**
 * Renders the links to pricing, help center, and mailing list pages.
 */
export const MarketingLinks: React.FC = () => {
  return (
    <Box
      direction="row-responsive"
      gap="medium"
      margin={{ horizontal: 'auto' }}
    >
      <Trans i18nKey="pricing-and-faq" ns={MARKETING_LINKS_I18N_NS}>
        <Anchor href={urlFor('pricing')()} margin="auto" variation="primary">
          Pricing and FAQ
        </Anchor>
      </Trans>
      <Trans i18nKey="help-center" ns={MARKETING_LINKS_I18N_NS}>
        <Anchor
          href={urlFor('help')()}
          margin="auto"
          rel="noopener noreferrer"
          target="_blank"
          variation="primary"
        >
          Help Center
        </Anchor>
      </Trans>
      <Trans i18nKey="join-mailing-list" ns={MARKETING_LINKS_I18N_NS}>
        <Anchor href={urlFor('subscribe')()} margin="auto" variation="primary">
          Join our Mailing List
        </Anchor>
      </Trans>
    </Box>
  );
};

import React from 'react';
import { Box, Heading, Image } from 'grommet';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router';
import styled from 'styled-components';
import { CheckNormalizedNameAvailabilityComponent } from '../../__generated__/graphql-code-generator';
import { getAppConfig } from '../../app-config';
import { PageContainer } from '../../components';
import { MarketingLinks } from '../../components/authentication/MarketingLinks';
import { SelectSiteForm } from '../../components/authentication/SelectSiteForm';
import { RegisterLink } from './components';
import SELECT_SITE_PAGE_IMG_SRC from './images/select-site.svg';

/**
 * URL search query params for the select-site page.
 */
export type SelectSitePageQueryParams = {
  nextUrl?: string | null;
  utm?: string | null;
};

/**
 * Parameters for Select Site Url Resolver
 */
export type SelectSiteUrlResolver = (
  tenantName: string,
  nextUrl?: string | null
) => string;

const selectSiteUrlResolver: SelectSiteUrlResolver = (tenantName, nextUrl) => {
  const { TENANT_OID_AUTHORIZE_URL } = getAppConfig();
  const redirectUrl = new URL(
    TENANT_OID_AUTHORIZE_URL.replace('{tenantName}', tenantName)
  );
  redirectUrl.pathname = nextUrl || '';
  return redirectUrl.toString();
};

const SignUpImage = styled(Image)`
  ${props => `
    @media only screen and (max-width: ${props.theme.global.breakpoints.small.value}px) {
      margin: 0 auto;
      width: 80%;
    }

    margin-right: ${props.theme.global.edgeSize.medium};
    width: 50%;
  `}
`;

const SignUpCTAContainer = styled(Box)`
  ${props => `
    @media only screen and (max-width: ${props.theme.global.breakpoints.small.value}px) {
      align-items: center;
      margin: 0 auto;
      width: 85%;
    }
  `}
`;

/**
 * Renders the select-site page.
 */
export const SelectSitePage: React.FC<RouteChildrenProps> = props => {
  const [t] = useTranslation('pages/SelectSitePage');

  // Retrieve query params
  const params = new URLSearchParams(props.location.search);
  const nextUrlParam = params.get('n');
  const utmParam = params.get('utm');

  return (
    <PageContainer width={{ max: '545px' }}>
      <CheckNormalizedNameAvailabilityComponent variables={{ input: 'test' }}>
        {({ refetch }) => {
          const domainExistsValidator = async (siteDomain: string) => {
            if (!siteDomain) {
              return Promise.resolve(false);
            }

            const response = await refetch({
              input: siteDomain,
            });

            const result =
              (response &&
                response.data &&
                !response.data.checkNormalizedNameAvailability
                  .isNormalizedNameAvailable) ||
              false;

            return Promise.resolve(result);
          };
          return (
            <SelectSiteForm
              domainExistsValidator={domainExistsValidator}
              nextUrl={nextUrlParam}
              selectSiteUrlResolver={selectSiteUrlResolver}
            />
          );
        }}
      </CheckNormalizedNameAvailabilityComponent>

      <Box
        align="center"
        direction="row-responsive"
        margin={{ bottom: 'medium', top: 'large' }}
      >
        <SignUpImage alt={t('sign-up')} src={SELECT_SITE_PAGE_IMG_SRC} />
        <SignUpCTAContainer align="start" direction="column">
          <Heading level="3" size="small">
            Don&apos;t have an account yet?
          </Heading>
          <RegisterLink nextUrl={nextUrlParam} utm={utmParam} />
        </SignUpCTAContainer>
      </Box>

      <MarketingLinks />
    </PageContainer>
  );
};

import React, { useContext } from 'react';
import { ThemeContext } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { IconBaseProps } from 'react-icons';
import { VoleerIconSize, VoleerTheme } from '../../theme';

export type IconProps = IconBaseProps & {
  icon: React.ComponentType<IconBaseProps>;
  color?: string | { dark?: string; light?: string };
  size?: VoleerIconSize | string;
};

/**
 * Renders the given `react-icons` icon component using theme settings.
 *
 * ```javascript
 * import { FaFile } from 'react-icons/fa';
 *
 * // With default settings:
 * <Icon icon={FaFile} />
 *
 * // Customized:
 * <Icon icon={FaFile} size="large" color="my-theme-color" />
 * ```
 */
export const Icon: React.FC<IconProps> = ({
  icon: TheIcon,
  size = 'medium',
  color,
  ...iconProps
}) => {
  const theme = useContext<VoleerTheme>(ThemeContext);

  // If the passed size is a named size from the theme then we need to get the
  // real size value from the theme
  const themeSize = theme.icon?.size?.[size];

  // If a color was specified in props use that, otherwise use the default icon
  // color from the theme
  const themeColor = color || theme.global?.colors?.icon;

  return (
    <TheIcon
      color={themeColor && normalizeColor(themeColor, theme)}
      size={themeSize || size}
      {...iconProps}
    />
  );
};

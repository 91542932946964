import React from 'react';
import { normalizeColor } from 'grommet/utils';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import styled from 'styled-components';

export type LinkProps = ReactRouterLinkProps & {
  disabled?: boolean;
  variation: 'primary' | 'secondary' | 'unstyled';
};

const LinkComponent: React.FC<LinkProps> = ({ type, ...props }) => {
  return <ReactRouterLink {...props} />;
};

/**
 * Extension of a react-router-dom that expose multiple type for different style presets.
 */
export const Link = styled(LinkComponent)`
  text-decoration: ${props => {
    switch (props.variation) {
      case 'primary':
        return 'underline';
      case 'secondary':
      case 'unstyled':
        return 'none';
    }
  }};
  font-weight: inherit;
  color: ${props => {
    switch (props.variation) {
      case 'primary':
        return normalizeColor('brand', props.theme);
      case 'secondary':
        return normalizeColor('dark-2', props.theme);
      case 'unstyled':
        return 'inherit';
    }
  }};
  &:hover {
    text-decoration: ${props => {
      switch (props.variation) {
        case 'primary':
        case 'secondary':
          return 'underline';
        case 'unstyled':
          return 'none';
      }
    }};
    color: ${props => {
      switch (props.variation) {
        case 'primary':
        case 'secondary':
          return normalizeColor('brand', props.theme);
        case 'unstyled':
          return 'inherit';
      }
    }};
  }
  ${props => props.disabled && 'pointer-events: none;'}
`;

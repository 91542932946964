import React, { useEffect } from 'react';
/**
 * Initializes the pendo script and adds anonymous id
 */
export const PendoInitialization: React.FC = () => {
  useEffect(() => {
    if (!window || !window.pendo) {
      return;
    }

    // Call this whenever information about your visitors becomes available
    // Please use Strings, Numbers, or Bools for value types.

    window.pendo.initialize({
      visitor: {
        id: '', // Sending an empty string and pendo will generate an anonymous id
      },
    });
  }, []);

  return null;
};

import React from 'react';
import { PropsOf } from '@voleer/types';
import styled from 'styled-components';
import { PageHeading } from '../../../../components';

type RegisterPageHeadingComponentProps = Readonly<
  PropsOf<typeof PageHeading> & {
    /**
     * Whether or not the heading animation is shown.
     */
    showAnimation: boolean;
  }
>;

const RegisterPageHeadingComponent: React.FC<RegisterPageHeadingComponentProps> =
  props => <PageHeading {...props} />;

/**
 * Renders the animated heading on the registration page.
 */
export const RegisterPageHeading = styled(RegisterPageHeadingComponent)`
  ${props => {
    return `
      @media only screen and (max-width: ${
        props.theme.global.breakpoints.medium.value
      }px) {
        text-align: center;
      }

      opacity: ${props.showAnimation ? '1' : '0'};
      transform: ${props.showAnimation ? 'translateY(0)' : 'translateY(-1rem)'};
      transition-delay: 200ms;
      transition-duration: 1s;
      transition-property: opacity, transform;
      transition-timing-function: ease-out;
    `;
  }}
`;

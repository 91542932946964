import { Button } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import styled from 'styled-components';

/**
 * Renders a Grommet `<Button>` without a border.
 */
export const GhostButton = styled(Button)`
  background: ${props => !props.active && 'transparent'};
  border: none;

  svg {
    fill: ${props => !props.disabled && normalizeColor('brand', props.theme)};
  }
`;

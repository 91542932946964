import React from 'react';
import { PropsOf } from '@voleer/types';
import { Heading } from 'grommet';
import styled from 'styled-components';

type PageHeadingProps = Readonly<PropsOf<typeof Heading>>;

const ResponsiveHeading = styled(Heading)`
  ${props => {
    return `
      @media only screen and (max-width: ${props.theme.global.breakpoints.xsmall.value}px) {
        padding-bottom: ${props.theme.global.edgeSize.medium};
        text-align: center;
        width: 100%;
      }
    `;
  }}
`;

/**
 * Renders the heading of an authentication-ui page.
 */
export const PageHeading: React.FC<PageHeadingProps> = props => {
  return (
    <ResponsiveHeading
      level="1"
      margin={{ bottom: 'medium' }}
      size="small"
      {...props}
    />
  );
};

import { ComponentType, ReactElement } from 'react';
import { PropsOf } from '.';

/**
 * Represents a `ReactNode` of the given component type.
 */
export type ElementOf<TComponent extends ComponentType> = ReactElement<
  PropsOf<TComponent>,
  TComponent
>;

/**
 * Type guard to ensure that the given `ReactNode` is an element of the given
 * component.
 *
 * ```typescript
 * const CoolComponent: React.FC = () => <div>I am so cool</div>;
 *
 * const UncoolComponent: React.FC = () => <div>I am not so cool</div>;
 *
 * const coolNode = <CoolComponent />;
 * const uncoolNode = <UncoolComponent />;
 *
 * isElementOf(CoolComponent, coolNode); // true
 * isElementOf(CoolComponent, uncoolNode); // false
 * ```
 *
 * @param component
 * @param node
 */
export const isElementOf = <TComponent extends ComponentType>(
  component: TComponent,
  node: unknown
): node is ElementOf<TComponent> => {
  if (!node) {
    return false;
  }
  const maybeComponent = node as ReactElement<PropsOf<TComponent>, TComponent>;
  return maybeComponent.type === component;
};

import React, { useContext, useMemo, useState } from 'react';
import { PropsOf } from '@voleer/types';
import {
  Box,
  BoxProps,
  Button,
  DropButton as GrommetDropButton,
  DropButtonProps as GrommetDropButtonProps,
} from 'grommet';
import { deepMerge, normalizeColor } from 'grommet/utils';
import { noop } from 'lodash';
import styled, { css } from 'styled-components';

type DropButtonContextValue = {
  /**
   * The `open` prop provided to `DropButton`.
   */
  open?: boolean;

  /**
   * Opens or closes the `DropButton` menu.
   */
  setIsOpen: (open: boolean) => void;
};

const DropButtonContext = React.createContext<DropButtonContextValue>({
  setIsOpen: noop,
});

type DropButtonItemProps = PropsOf<typeof Button> & {
  label?: React.ReactNode;
  pad?: BoxProps['pad'];
  border?: BoxProps['border'];
};

const DropButtonItemComponent: React.FC<DropButtonItemProps> = ({
  label,
  pad: originalPad,
  border,
  onClick: originalOnClick,
  ...buttonProps
}) => {
  const { open, setIsOpen } = useContext(DropButtonContext);

  const onClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> &
      React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // In order to allow callers to control the open state of the dropdown, we
    // avoid automatically closing the dropdown when clicking on a drop item if
    // the caller has specified an explicit open state
    if (typeof open !== 'boolean') {
      setIsOpen(false);
    }

    if (originalOnClick) {
      originalOnClick(event);
    }
  };

  const pad =
    typeof originalPad === 'string'
      ? originalPad
      : deepMerge(
          {
            horizontal: 'small',
            vertical: 'xsmall',
          },
          originalPad || {}
        );

  return (
    <Button
      label={
        <Box border={border} pad={pad}>
          {label}
        </Box>
      }
      onClick={onClick}
      plain={true}
      {...buttonProps}
    />
  );
};

/**
 * Renders a `DropButton` menu item.
 */
export const DropButtonItem = styled(DropButtonItemComponent)`
  color: ${props =>
    props.disabled
      ? normalizeColor('light-4', props.theme)
      : normalizeColor(props.color || 'text', props.theme)};
  width: 100%;
  &:hover,
  &:focus {
    background-color: ${props => normalizeColor('light-2', props.theme)};
  }
`;

type DropButtonProps = Omit<
  PropsOf<typeof GrommetDropButton>,
  'dropContent' | 'primary'
> & {
  variation?: 'ghost' | 'primary' | 'secondary';
};

const DropButtonComponent = React.forwardRef<
  HTMLButtonElement,
  DropButtonProps
>(
  (
    {
      variation = 'secondary',
      open: originalOpen,
      dropProps: originalDropProps,
      dropAlign: originalDropAlign,
      children,
      ...dropButtonProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = dropButtonProps.onClick || (() => setIsOpen(!isOpen));

    const dropProps = deepMerge(
      {
        onClickOutside: () => setIsOpen(false),
        onEsc: () => setIsOpen(false),
      },
      originalDropProps || {}
    );

    const dropAlign = deepMerge(
      {
        top: 'bottom',
        right: 'right',
      },
      originalDropAlign || {}
    );

    const open = typeof originalOpen === 'boolean' ? originalOpen : isOpen;

    const contextValue: DropButtonContextValue = useMemo(
      () => ({
        setIsOpen,
        open: originalOpen,
      }),
      [originalOpen]
    );

    return (
      <DropButtonContext.Provider value={contextValue}>
        <GrommetDropButton
          dropAlign={dropAlign}
          dropProps={dropProps}
          onClick={onClick}
          open={open}
          primary={variation === 'primary'}
          {...(dropButtonProps as GrommetDropButtonProps)}
          dropContent={<>{children}</>}
          ref={ref}
        />
      </DropButtonContext.Provider>
    );
  }
);

/**
 * Specialized version of Grommet's DropButton component which adds some
 * defaults and conveniences for dropdown menus in Voleer:
 *
 * * Automatically handles opening the menu on click
 * * Provides defaults for `onClickOutside`/`onEsc`/etc. to close the menu
 * * Applies Voleer button styles
 * * Etc...
 *
 * ```typescript
 * <DropButton label="My Drop Menu">
 *   <DropButtonItem label="First Item" onClick={onClick} />
 *   <DropButtonItem label="Second Item" onClick={onClick} />
 *   <DropButtonItem
 *     label={<Text color="status-error">Third Item</Text>}
 *     onClick={onClick}
 *   />
 * </DropButton>
 * ```
 */
export const DropButton = styled(DropButtonComponent)`
  ${props => {
    if (!props.plain && props.variation === 'ghost') {
      return css`
        background-color: transparent;
        border: 0;
      `;
    }
    return '';
  }}

  ${props => {
    if (!props.plain && props.variation !== 'primary') {
      const brandColor = normalizeColor('brand', props.theme);
      return css`
        color: ${brandColor};
      `;
    }
    return '';
  }}
`;

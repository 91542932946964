import React from 'react';
import { FormikSubmitFn } from '@voleer/form-utils';
import { Alert } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { FormField, Heading, Paragraph, TextInput } from 'grommet';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormContainer } from '../FormContainer';
import { ResendCodeButton } from './components';

const defaultValues = {
  shortToken: '',
};

export type ShortTokenFormValues = typeof defaultValues;

type ShortTokenFormProps = Readonly<{
  email: string | undefined;
  onCodeResend: () => Promise<string>;
  onSubmit: FormikSubmitFn<ShortTokenFormValues>;
}>;

const createSubmitHandler =
  (onSubmit: FormikSubmitFn<ShortTokenFormValues>) =>
  async (
    values: ShortTokenFormValues,
    formikActions: FormikHelpers<ShortTokenFormValues>
  ) => {
    try {
      await onSubmit(values, formikActions);
    } catch (error) {
      formikActions.setSubmitting(false);
      formikActions.setStatus({
        serverError: error.toString(),
      });
    }
  };

/**
 * Styled input field for the short token.
 */
const ShortTokenTextInput = styled(TextInput)`
  border-radius: 8px;
  height: 38px;
  font-size: 34px;
  text-align: center;
`;

/**
 * Renders the short token form.
 */
export const ShortTokenForm: React.FC<ShortTokenFormProps> = ({
  email,
  onCodeResend,
  onSubmit,
}) => {
  const [t] = useTranslation('components/authentication/ShortTokenForm');

  return (
    <FormContainer
      margin={{ top: 'medium' }}
      pad={{ bottom: 'medium', horizontal: 'medium', top: 'small' }}
    >
      <Heading color="neutral-3" level="2" size="small">
        {t('heading')}
      </Heading>

      <Paragraph margin={{ vertical: 'small' }}>
        {email ? (
          <Trans i18nKey="instructions.with-email" t={t}>
            <>We sent a confirmation code to</>
            <strong> {{ email }}</strong>
            <>
              . Enter the six-digit code here to confirm your account and get
              started!
            </>
          </Trans>
        ) : (
          t('instructions.without-email')
        )}
      </Paragraph>

      <Formik
        initialValues={defaultValues}
        onSubmit={createSubmitHandler(onSubmit)}
        validateOnChange={false}
      >
        {formProps => {
          const onCodeInput = (
            event: React.SyntheticEvent<HTMLInputElement>
          ) => {
            const value = event.currentTarget.value;
            formProps.setValues({ shortToken: value });

            if (value.length === 6) {
              formProps.submitForm();
            }
          };
          return (
            <Form>
              {formProps.status && formProps.status.serverError ? (
                <Alert margin={{ bottom: 'medium' }} status="error">
                  {formProps.status.serverError}
                </Alert>
              ) : null}
              <Field name="shortToken">
                {(fieldProps: FieldProps<string>) => {
                  return (
                    <FormField
                      error={
                        fieldProps.form.touched.shortToken &&
                        fieldProps.form.errors.shortToken
                      }
                    >
                      <ShortTokenTextInput
                        {...fieldProps.field}
                        autoFocus={true}
                        disabled={
                          formProps.isValidating || formProps.isSubmitting
                        }
                        maxLength={6}
                        onChange={onCodeInput}
                        placeholder="######"
                      />
                    </FormField>
                  );
                }}
              </Field>
            </Form>
          );
        }}
      </Formik>

      <ResendCodeButton
        color="brand"
        label={t('resend-code.label')}
        margin={{ top: 'medium' }}
        onCodeResend={onCodeResend}
        plain={true}
      />
    </FormContainer>
  );
};

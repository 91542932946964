import { JSONArray, JSONObject, JSONPrimitive, JSONValue } from '@voleer/types';
import * as t from 'io-ts';

/**
 * io-ts type representing JSON-compatible primitives.
 */
export const JSONPrimitiveT: t.Type<JSONPrimitive> = t.union(
  [t.string, t.number, t.boolean, t.null],
  'JSONPrimitive'
);

/**
 * io-ts type representing JSON-compatible objects.
 */
export const JSONObjectT: t.Type<JSONObject> = t.recursion('JSONObject', () =>
  t.record(
    t.string,
    t.union([t.undefined, JSONPrimitiveT, JSONArrayT, JSONObjectT])
  )
);

/**
 * io-ts type representing JSON-compatible arrays.
 */
export const JSONArrayT: t.Type<JSONArray> = t.recursion('JSONArray', () => {
  return t.array(t.union([JSONPrimitiveT, JSONArrayT, JSONObjectT]));
});

/**
 * io-ts type representing JSON-compatible values.
 */
export const JSONValueT: t.Type<JSONValue> = t.union(
  [JSONPrimitiveT, JSONObjectT, JSONArrayT],
  'JSONValue'
);

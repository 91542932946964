/**
 * Type guard to check if the given value is a Promise.
 *
 * @param value
 */
export const isPromise = <TValue>(value: unknown): value is Promise<TValue> => {
  if (!value) {
    return false;
  }
  return typeof (value as Promise<TValue>).then === 'function';
};

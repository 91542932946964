import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Registers a Marketo visitWebPage on location change
 */
export const Marketo: React.FC = () => {
  const location = useLocation();
  const currentPathRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    const currentPath = `${location.pathname}${location.search}`;

    // Ignore the current path if we have already recorded it
    if (currentPath === currentPathRef.current) {
      return;
    }

    // Verify window and document globals exist
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Munchkin = (window as any).Munchkin;

    // Verfiy that Munchkin (Marketo tracking) exists
    if (!Munchkin || typeof Munchkin.munchkinFunction !== 'function') {
      return;
    }

    // Save page view to Marketo
    Munchkin.munchkinFunction('visitWebPage', {
      url: location.pathname,
      params: location.search,
    });

    currentPathRef.current = currentPath;
  }, [location]);

  return null;
};

import React from 'react';
import { Box, Image } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { urlFor } from '../../App';
import VOLEER_LOGO_IMG_SRC from './images/voleer-logo.svg';

/**
 * Renders the inner container of the `Navbar` component.
 */
const NavbarInner = styled(Box)`
  ${props => {
    return `
    // Increase height on small screens
    @media screen and (max-width: ${props.theme.global.breakpoints.xsmall.value}px) {
      height: 50px;
    }
      
    height: 39px; // Ensures consistent sizing w/ marketing site
    max-width: ${props.theme.global.breakpoints.medium.value}px;
    width: 100%;
    `;
  }}
`;

const VOLEER_LOGO_HEIGHT = '30px';
/**
 * Renders the link that wraps the Voleer logo.
 */
const LogoLink = styled.a`
  height: ${VOLEER_LOGO_HEIGHT};
  margin-right: auto;
`;

/**
 * Renders the styled links placed in the `Navbar` component.
 */
const NavbarLink = styled(NavLink)`
  ${props => {
    return `
      font-size: 1.25rem;      
      transition: color 300ms;
      text-decoration: none;

      &,
      &:visited {
        color: ${normalizeColor('neutral-4', props.theme)};
      }

      &:hover,
      &:focus {
        color: ${normalizeColor('neutral-4', props.theme)};
      }

      &.navbar__link--active {
        display: none;
      }
    `;
  }}
`;

/**
 * Renders the navigation bar at the top of pages in authentication-ui.
 */
export const Navbar: React.FC = () => {
  const [t] = useTranslation('components/Navbar');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <Box
      align="center"
      background="white"
      margin={{ bottom: 'large' }}
      pad={{ horizontal: '4vw', vertical: '1.2vw' }}
      width="100vw"
    >
      <NavbarInner align="center" direction="row">
        <LogoLink
          data-testid="navbar__link--marketing-site"
          href={urlFor('marketingSite')()}
          title="voleer.com"
        >
          <Image
            alt={t('logo.alt-text')}
            height={VOLEER_LOGO_HEIGHT}
            src={VOLEER_LOGO_IMG_SRC}
          />
        </LogoLink>

        <NavbarLink
          activeClassName="navbar__link--active"
          data-testid="navbar__link--select-site"
          to={urlFor('selectSite')({
            nextUrl: searchParams.get('n'),
            utm: searchParams.get('utm'),
          })}
        >
          {t('signin')}
        </NavbarLink>
      </NavbarInner>
    </Box>
  );
};

import { normalizeColor } from 'grommet/utils';
// @ts-ignore Grommet doesn't provide types for `focusStyle` at this time
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
import { focusStyle } from 'grommet/utils';
import { normalize } from 'polished';
import {
  InterpolationFunction,
  ThemeProps,
  createGlobalStyle,
  css,
} from 'styled-components';
import { scaleBy } from '../utils/styles';
import { VoleerTheme } from './voleer2';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * Styles for customizing react-datepicker.
 */
const datePickerStyles: InterpolationFunction<ThemeProps<VoleerTheme>> = ({
  theme,
}) => {
  return css`
    .voleer-datepicker .react-datepicker {
      border-color: ${normalizeColor('neutral-1', theme)};
      border-radius: 8px;
      border-style: solid;
      border-width: ${theme?.global?.borderSize?.xsmall};
      box-shadow: 0 0 8px rgba(48, 58, 66, 0.2);

      &__navigation {
        color: ${normalizeColor('neutral-2', theme)};
      }

      &__header {
        background-color: ${normalizeColor('white', theme)};
        border-radius: 8px;
        border: none;
        font-family: ${theme?.global?.font?.family};
        font-style: normal;
        font-weight: normal;
      }

      &__day,
      &__time-list-item {
        border-radius: 4px;
        color: ${normalizeColor('dark-1', theme)};
      }

      &__day-name {
        color: ${normalizeColor('dark-1', theme)};
      }

      &__day--disabled,
      &__day--outside-month {
        color: ${normalizeColor('light-4', theme)};
      }

      &__day--keyboard-selected,
      &__day--selected {
        color: ${normalizeColor('white', theme)};
        background-color: ${normalizeColor('brand', theme)};
      }

      &__time-list-item--selected {
        color: ${normalizeColor('white', theme)};
        background-color: ${normalizeColor('brand', theme)} !important;
      }

      &__time-container {
        border-left: none;
        border-radius: 0em 8px 8px 0em;
        overflow: hidden;
      }
    }
  `;
};

/**
 * Global styles to support the Voleer theme.
 */
export const VoleerGlobalStyles = createGlobalStyle<ThemeProps<VoleerTheme>>`
${props => {
  const baseFontSize = props.theme.global?.font?.size;
  return css`
    ${normalize()}

    html,body,#root {
      font-size: ${baseFontSize};
      height: 100vh;
    }

    body {
      font-family: ${props => props.theme?.global?.font?.family};
      font-size: ${baseFontSize};
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: ${baseFontSize && scaleBy(0.8, baseFontSize)} 0;
    }

    // Hide outline and box shadow for button elements unless navigating with keyboard
    // https://css-tricks.com/keyboard-only-focus-styles/
    button {
      outline: none !important;
    }
    button:not(:focus-visible) {
      box-shadow: none !important;
    }

    // Apply Grommet's focus styles in the global stylesheet.
    // Currently Grommet only applies focus styling when the
    // onFocus event is fired on inputs, which means that focus
    // styling won't be applied immediately if an element is
    // focused during server-side rendering.
    // https://github.com/grommet/grommet/blob/master/src/js/components/TextInput/TextInput.js
    // Fixes: https://dev.azure.com/bittitan/Voleer/_sprints/taskboard/Platform%20Team/Voleer/2021?workitem=3232
    :focus {
      ${focusStyle()}
    }
    ${datePickerStyles}
  `;
}}
`;

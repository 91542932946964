import React from 'react';
import { Box, Image, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AD_LIST_BULLET_POINT_IMG_SRC from '../../images/bullet-point.svg';

const StyledList = styled.ul`
  ${props => {
    return `
      list-style-type: none;
      padding-left: 0;

      li ~ li {
        padding-top: ${props.theme.global.edgeSize.small};
      }
    `;
  }}
`;

/**
 * Renders the bulleted list of features in the marketing ad on the registration page.
 */
export const AdList: React.FC = () => {
  const [t] = useTranslation('pages/RegisterPage');

  const adListBulletPoints = [
    t('ad-list.item-1'),
    t('ad-list.item-2'),
    t('ad-list.item-3'),
  ];

  return (
    <StyledList>
      {adListBulletPoints.map(point => {
        return (
          <li key={point}>
            <Box align="center" direction="row">
              <Box
                animation={{ duration: 1000, type: 'fadeIn' }}
                width={{ min: '20px' }}
              >
                <Image
                  height="20px"
                  margin={{ right: 'xsmall' }}
                  src={AD_LIST_BULLET_POINT_IMG_SRC}
                />
              </Box>
              &nbsp;
              <Paragraph margin="none" size="xlarge">
                {point}
              </Paragraph>
            </Box>
          </li>
        );
      })}
    </StyledList>
  );
};

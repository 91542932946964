import React from 'react';
import { Alert } from '@voleer/ui-kit';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, FormField, Heading, Paragraph, TextInput } from 'grommet';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getAppConfig } from '../../../app-config';
import { SelectSiteUrlResolver } from '../../../pages';
import { FormContainer } from '../../authentication/FormContainer';

const I18N_NAMESPACE = 'components/authentication/SelectSiteForm';

const defaultFormValues = {
  tenantName: '',
};

type TenantRedirectFormValues = {
  tenantName: string;
};

const createSubmitHandler =
  (selectSiteUrlResolver: SelectSiteUrlResolver, nextUrl?: string | null) =>
  (
    values: TenantRedirectFormValues,
    formikActions: FormikHelpers<TenantRedirectFormValues>
  ) => {
    try {
      formikActions.setSubmitting(true);
      const tenantName = values.tenantName.replace(/\s/g, '').toLowerCase();
      const redirectUrl = selectSiteUrlResolver(tenantName, nextUrl);
      window.location.href = redirectUrl;
    } catch (error) {
      formikActions.setSubmitting(false);
      formikActions.setStatus({ serverError: error });
    }
  };

/**
 * Props for SelectSiteForm component
 */
export type SelectSiteFormProps = {
  selectSiteUrlResolver: SelectSiteUrlResolver;
  /**
   * Validator function to verify the given domain exists.
   */
  domainExistsValidator: (domain: string) => Promise<boolean> | boolean;
  nextUrl?: string | null;
};

const validationSchema = ({
  t,
  domainExistsValidator,
}: {
  t: typeof i18next.t;
  domainExistsValidator: SelectSiteFormProps['domainExistsValidator'];
}) => {
  return Yup.object().shape({
    tenantName: Yup.string()
      .trim()
      .required(t('form.validation.domain-required'))
      .test(
        'unique-domain',
        t('form.validation.domain-does-not-exist'),
        async value => {
          if (typeof value !== 'string') {
            return false;
          }
          return await domainExistsValidator(value);
        }
      ),
  });
};

const ContinueButton = styled(Button)`
  ${props => {
    return `
      border-radius: ${props.theme.global.edgeSize.xsmall};
      font-size: 16px;      
      padding: ${props.theme.global.edgeSize.xsmall} ${props.theme.global.edgeSize.medium};
    `;
  }}
`;

/**
 * Renders the tenant redirect form.
 */
export const SelectSiteForm: React.FC<SelectSiteFormProps> = ({
  selectSiteUrlResolver,
  domainExistsValidator,
  nextUrl,
}) => {
  const [t] = useTranslation(I18N_NAMESPACE);

  const { ROOT_DOMAIN } = getAppConfig();
  return (
    <FormContainer>
      <Heading color="neutral-3" level="2" size="small">
        {t('heading.title')}
      </Heading>

      <Formik
        initialValues={defaultFormValues}
        onSubmit={createSubmitHandler(selectSiteUrlResolver, nextUrl)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema({ domainExistsValidator, t })}
      >
        {formProps => (
          <Form>
            {formProps.status && formProps.status.serverError && (
              <Alert status="error">{formProps.status.serverError}</Alert>
            )}
            <Box direction="row" gap="xsmall">
              <Box width="70%">
                <Field name="tenantName">
                  {({ field }: FieldProps) => (
                    <FormField
                      error={formProps.errors.tenantName}
                      label={t('form.tenant-name.label')}
                    >
                      <TextInput
                        {...field}
                        autoFocus={true}
                        disabled={formProps.isSubmitting}
                        placeholder={t('form.tenant-name.placeholder')}
                      />
                    </FormField>
                  )}
                </Field>
              </Box>
              <Paragraph margin={{ bottom: '0', top: '40px' }}>
                .{ROOT_DOMAIN}
              </Paragraph>
            </Box>
            <Box>
              <ContinueButton
                disabled={formProps.isSubmitting}
                label={t('form.submit.label')}
                margin={{ horizontal: 'auto', top: 'small' }}
                primary={true}
                type="submit"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

import { useLayoutEffect, useRef } from 'react';

/**
 * Preserves the scroll position of the given HTML element even when its
 * child elements are re-ordered.
 *
 * Reordering child HTML elements can cause the browser to automatically scroll
 * to the new position of the reordered element, so this is useful in situations
 * where we want to prevent that from happening.
 *
 * ```typescript
 * const ref = useRef<HTMLElement>();

 * usePreserveScrollPosition(ref);
 *
 * return (
 *   <SomeExample ref={ref}>
 *     Reorderable content goes here
 *   </SomeExample>
 * );
 * ```
 */
export const usePreserveScrollPosition = (
  ref: React.MutableRefObject<HTMLElement | null | undefined>
) => {
  const scrollTopRef = useRef<number>();

  useLayoutEffect(() => {
    const el = ref.current;

    if (
      typeof scrollTopRef.current === 'number' &&
      el &&
      el.scrollTop !== scrollTopRef.current
    ) {
      el.scrollTop = scrollTopRef.current;
    }

    const scrollListener = (e: Event) => {
      const target = e.target as Element;
      scrollTopRef.current = target.scrollTop;
    };

    el?.addEventListener('scroll', scrollListener);

    return () => {
      el?.removeEventListener('scroll', scrollListener);
    };
  });
};

import React, { useContext } from 'react';
import { ensureUnreachable } from '@voleer/types';
import { Icon, RotatingIcon, VoleerTheme } from '@voleer/ui-kit';
import { Box, BoxTypes, Text, TextProps } from 'grommet';
import {
  // @ts-ignore - grommet does not provide types for colorIsDark utility at this time
  colorIsDark,
  normalizeColor,
} from 'grommet/utils';
import { IconBaseProps } from 'react-icons';
import {
  FaCheck,
  FaExclamationCircle,
  FaQuestion,
  FaRegTimesCircle,
  FaSpinner,
} from 'react-icons/fa';
import styled, { ThemeContext, css } from 'styled-components';

export enum BadgeStatus {
  error = 'error',
  info = 'info',
  ok = 'ok',
  warning = 'warning',
  processing = 'processing',
  unknown = 'unknown',
}

type BadgeIconProps = Readonly<{
  /**
   * Controls the icon for the badge.
   *
   * * If `true` (default), a default icon will be displayed based on the status
   * * If `false`, no icon will be displayed
   * * If given a icon component then that icon will be displayed
   */
  icon?: React.ComponentType<IconBaseProps> | boolean;

  status: BadgeStatus;
}>;

export type BadgeProps = Readonly<
  Pick<BadgeIconProps, 'icon'> &
    Pick<BoxTypes, 'margin' | 'title'> &
    Pick<TextProps, 'truncate'> & {
      label: React.ReactNode;
      status: BadgeStatus;
    }
>;

/**
 * Computes the color associated with the given alert status.
 */
const statusColor = (status: BadgeStatus) => {
  switch (status) {
    case BadgeStatus.ok:
      return 'status-ok-light';
    case BadgeStatus.info:
      return 'status-info';
    case BadgeStatus.warning:
    case BadgeStatus.unknown:
      return 'status-warning-light';
    case BadgeStatus.error:
      return 'status-error-light';
    case BadgeStatus.processing:
      return 'status-processing-light';
    default:
      ensureUnreachable(status);
      return 'dark-2';
  }
};

/**
 * Computes the icon associated with the given alert status.
 */
const statusIcon = (status: BadgeStatus) => {
  switch (status) {
    case BadgeStatus.ok:
      return FaCheck;
    case BadgeStatus.info:
    case BadgeStatus.processing:
      return FaSpinner;
    case BadgeStatus.warning:
      return FaExclamationCircle;
    case BadgeStatus.error:
      return FaRegTimesCircle;
    case BadgeStatus.unknown:
      return FaQuestion;
    default:
      ensureUnreachable(status);
      return FaSpinner;
  }
};

/**
 * Renders an icon in the Badge.
 */
const BadgeIcon: React.FC<BadgeIconProps> = ({ icon = true, status }) => {
  const theme = useContext<VoleerTheme>(ThemeContext);

  if (!icon) {
    return null;
  }

  const renderedStatusIcon = icon === true ? statusIcon(status) : icon;

  const backgroundColor = normalizeColor(statusColor(status), theme);
  const color = colorIsDark(backgroundColor, theme) ? 'light-1' : 'dark-1'; // Use light or dark text depending on the background color
  const iconProps = {
    color: normalizeColor(color, theme),
    icon: renderedStatusIcon,
    size: 'small',
  };

  return renderedStatusIcon === FaSpinner ? (
    <RotatingIcon {...iconProps} />
  ) : (
    <Icon {...iconProps} />
  );
};

const BadgeComponent: React.FC<BadgeProps> = ({
  icon = true,
  label,
  status,
  truncate,
  ...props
}) => (
  <Box
    direction="row"
    flex={{ grow: 0, shrink: 0 }}
    width="fit-content"
    {...props}
  >
    <BadgeIcon icon={icon} status={status} />

    <Box margin={{ left: icon ? 'xsmall' : '0' }}>
      <Text size="small" truncate={truncate}>
        {label}
      </Text>
    </Box>
  </Box>
);

/**
 * Renders a Badge with a label and status color.
 */
export const Badge = styled(BadgeComponent)`
  ${({ status, theme }) => {
    const backgroundColor = normalizeColor(statusColor(status), theme);
    const borderRadius = theme.global.edgeSize.xsmall;
    const padHorizontal = theme.global.edgeSize.xsmall;

    // Use light or dark text depending on the background color
    const isBackgroundDark = colorIsDark(backgroundColor, theme);
    const color = isBackgroundDark ? 'light-1' : 'dark-1';

    return css`
      align-items: center;
      background-color: ${backgroundColor};
      border-radius: ${borderRadius};
      color: ${normalizeColor(color, theme)};
      display: inline-flex;
      padding-left: ${padHorizontal};
      padding-right: ${padHorizontal};
    `;
  }}
`;

import React, { useEffect, useState } from 'react';
import { GraphQLError } from 'graphql';
import { Box, Heading, Image } from 'grommet';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteChildrenProps } from 'react-router';
import styled from 'styled-components';
import {
  CheckNormalizedNameAvailabilityComponent,
  CreateTenantComponent,
} from '../../__generated__/graphql-code-generator';
import { urlFor } from '../../App';
import { getAppConfig } from '../../app-config';
import { PageContainer } from '../../components';
import { MarketingLinks } from '../../components/authentication/MarketingLinks';
import {
  RegisterForm,
  RegisterFormValues,
} from '../../components/authentication/RegisterForm';
import {
  AdList,
  ContactSalesText,
  RegisterPageHeading,
  SelectSiteLink,
} from './components';
import REGISTER_PAGE_IMG_SRC from './images/register-page.png';

/**
 * Collects any error codes present in the given array of GraphQL errors.
 *
 * @param errors
 */
const collectErrorCodes = (errors: readonly GraphQLError[]): string[] => {
  return errors.reduce((acc, error) => {
    const code = (error.extensions || {}).code;
    if (code) {
      acc.push(code);
    }
    return acc;
  }, [] as string[]);
};

const RegisterPageGridLayout = styled(Box)`
  ${props => {
    return `
      flex-direction: column;

      @media only screen and (min-width: ${props.theme.global.breakpoints.medium.value}px) {
        flex-direction: row;
        justify-content: space-between;
      }
    `;
  }}
`;

const DisplayOnLargeScreens = styled(Box)`
  ${props => {
    return `
      @media only screen and (max-width: ${props.theme.global.breakpoints.medium.value}px) {
        display: none;
      }
    `;
  }}
`;

const DisplayOnMediumScreensAndSmaller = styled(Box)`
  ${props => {
    return `
      @media only screen and (min-width: ${
        props.theme.global.breakpoints.medium.value + 1
      }px) {
        display: none;
      }
    `;
  }}
`;

/**
 * Query params for RegisterPage
 */
export type RegisterPageQueryParams = {
  nextUrl?: string | null;
  utm?: string | null;
};

/**
 * Renders the register page.
 */
export const RegisterPage: React.FC<RouteChildrenProps> = props => {
  const [t] = useTranslation('pages/RegisterPage');
  const [tTenantApi] = useTranslation('apis/TenantsApi');
  const { ROOT_DOMAIN } = getAppConfig();

  // Retrieve query params
  const params = new URLSearchParams(props.location.search);
  const nextUrl = params.get('n');

  const [showHeadingAnimation, setShowHeadingAnimation] =
    useState<boolean>(false);

  // The email address that will be displayed on the confirm-email page via
  // react-router location state.
  let email = '';

  // On initial render, show the animation on the heading
  useEffect(() => {
    setShowHeadingAnimation(true);
  }, []);

  return (
    <PageContainer>
      <RegisterPageGridLayout>
        <Box>
          <RegisterPageHeading showAnimation={showHeadingAnimation}>
            {t('heading')}
          </RegisterPageHeading>

          <DisplayOnLargeScreens fill="vertical">
            <Box
              animation={{ duration: 1500, type: 'fadeIn' }}
              height={{ min: '240px' }}
              margin={{ vertical: 'medium' }}
            >
              <Image
                alt={t('ad-img.alt-text')}
                margin={{ vertical: 'medium' }}
                src={REGISTER_PAGE_IMG_SRC}
                width="100%"
              />
            </Box>
            <Heading level="4">{t('ad-heading')}</Heading>
            <AdList />
            <ContactSalesText />
          </DisplayOnLargeScreens>
        </Box>

        <Box>
          <CreateTenantComponent>
            {(createTenantMutation, { data }) => {
              const onSubmit = async (values: RegisterFormValues) => {
                email = values.email;
                const response = await createTenantMutation({
                  variables: {
                    tenant: {
                      displayName: values.companyName,
                      name: values.siteDomain,
                    },
                    tenantUser: {
                      emailAddress: values.email,
                      password: values.password,
                      firstName: values.firstName,
                      lastName: values.lastName,
                    },
                    returnUrl: nextUrl ? nextUrl : '',
                  },
                });

                // TODO (cvalmonte): Temporary error handling?
                if (response && response.data && !response.data.createTenant) {
                  const errorCodes = collectErrorCodes(response.errors || []);
                  const errorMessage = errorCodes.length
                    ? errorCodes
                        .map(code => tTenantApi(`error-code.${code}`))
                        .join(' ')
                    : t('registration-failed');
                  return Promise.reject(errorMessage);
                }
                return Promise.resolve();
              };

              if (data && data.createTenant) {
                return (
                  <Redirect
                    to={{
                      pathname: urlFor('confirmEmail')(),
                      search: `${new URLSearchParams({
                        userId: data.createTenant.userId,
                        returnUrl: data.createTenant.returnUrl,
                      })}`,
                      state: { email },
                    }}
                  />
                );
              }

              return (
                <CheckNormalizedNameAvailabilityComponent
                  variables={{ input: 'test' }}
                >
                  {({ refetch }) => {
                    const uniqueDomainValidator = async (
                      siteDomain: string
                    ) => {
                      if (!siteDomain) {
                        return Promise.resolve(false);
                      }

                      const response = await refetch({
                        input: siteDomain,
                      });

                      return Promise.resolve(
                        (response &&
                          response.data &&
                          response.data.checkNormalizedNameAvailability
                            .isNormalizedNameAvailable) ||
                          false
                      );
                    };

                    return (
                      <RegisterForm
                        onSubmit={onSubmit}
                        rootDomain={ROOT_DOMAIN}
                        uniqueDomainValidator={uniqueDomainValidator}
                      />
                    );
                  }}
                </CheckNormalizedNameAvailabilityComponent>
              );
            }}
          </CreateTenantComponent>

          <Box align="center" pad="small">
            <SelectSiteLink nextUrl={nextUrl} utm={params.get('utm')} />
          </Box>

          <MarketingLinks />

          <DisplayOnMediumScreensAndSmaller>
            <ContactSalesText />
          </DisplayOnMediumScreensAndSmaller>
        </Box>
      </RegisterPageGridLayout>
    </PageContainer>
  );
};

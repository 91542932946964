import React from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { urlFor } from '../../App';

/**
 * Renders the login page.
 */
export const HomePage: React.FC<RouteChildrenProps> = () => (
  <Redirect to={urlFor('registration')()} />
);

import React from 'react';
import { Error404, Link } from '@voleer/ui-kit';
import { Box, Paragraph } from 'grommet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { urlFor } from '../../App';

/**
 * Renders a generic 404 page.
 */
export const Error404Page: React.FC = () => {
  const [t] = useTranslation('pages/Error404Page');

  return (
    <Box align="center" fill={true} margin={{ vertical: 'auto' }}>
      <Error404 helpContent={<HelpContent />} subheading={t('subheading')} />
    </Box>
  );
};

const HelpContentLink = styled(Link)`
  display: block;
  margin: 8px 0;
  text-decoration: underline;
`;

const HelpContent: React.FC = () => {
  const [t] = useTranslation('pages/Error404Page');

  return (
    <Paragraph margin={{ bottom: '0', top: 'small' }} size="large">
      {t('help.instructions')}
      <br />
      <HelpContentLink to={urlFor('registration')()} variation="secondary">
        {t('help.registration-link')}
      </HelpContentLink>
    </Paragraph>
  );
};

import 'url-search-params-polyfill';

import React from 'react';
import { createBrowserHistory } from 'history';
import { hydrate } from 'react-dom';
import { useSSR } from 'react-i18next';
import { Router } from 'react-router';
import App from './App';

// Initialize i18next
import './i18n-client';

const history = createBrowserHistory();

// Get initial i18n settings
const initialI18nLanguage = window.initialI18nLanguage;
const initialI18nStore = window.initialI18nStore;

const Root: React.FC = () => {
  useSSR(initialI18nStore, initialI18nLanguage);
  return (
    <Router history={history}>
      <App />
    </Router>
  );
};

hydrate(<Root />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}

import { useState } from 'react';
import { ModalProps } from './Modal';

type UseModalStateValue = {
  /**
   * Is the modal currently open?
   */
  isOpen: boolean;

  /**
   * Opens the modal.
   */
  open: () => void;

  /**
   * Closes the modal.
   */
  close: () => void;

  /**
   * Convenience props to pass to instances of Modal.
   */
  props: Pick<ModalProps, 'open'>;
};

/**
 * Hook for tracking and controlling the state of a `Modal`.
 */
export const useModalState = (): UseModalStateValue => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    props: { open: isOpen },
  };
};

import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Marketo } from '@voleer/marketo';
import { VoleerGlobalStyles, voleer2 } from '@voleer/ui-kit';
import { Box, Grommet } from 'grommet';
import {
  Route,
  RouteComponentProps,
  Router,
  Switch,
  withRouter,
} from 'react-router';
import { Navbar } from './components';
import { PendoInitialization } from './components/pendo-initialization';
import { createClient } from './graphql/client';
import {
  ConfirmEmailPage,
  Error404Page,
  HomePage,
  RegisterPage,
  RegisterPageQueryParams,
  SelectSitePage,
  SelectSitePageQueryParams,
} from './pages';

type AppProps = RouteComponentProps;

/**
 * Get Page URLs Functions
 */
const urls = {
  home: () => '/',
  confirmEmail: () => '/confirmEmail',
  registration: ({ nextUrl, utm }: RegisterPageQueryParams = {}) => {
    const pathname = '/register';
    if (!nextUrl && !utm) return pathname;

    const search = new URLSearchParams();
    if (nextUrl) search.append('n', nextUrl);
    if (utm) search.append('utm', utm);
    return `${pathname}?${search}`;
  },
  selectSite: ({ nextUrl, utm }: SelectSitePageQueryParams = {}) => {
    const pathname = '/select-site';
    if (!nextUrl && !utm) return pathname;

    const search = new URLSearchParams();
    if (nextUrl) search.append('n', nextUrl);
    if (utm) search.append('utm', utm);
    return `${pathname}?${search}`;
  },

  // External URLs
  help: () => 'https://go.voleer.com/help',
  marketingSite: () => 'https://voleer.com',
  pricing: () => 'https://go.voleer.com/pricing',
  subscribe: () => 'https://go.voleer.com/subscribe',
  contactSales: () => 'https://voleer.com/contact-sales/?SQF_PLAN=registration',
};

type Urls = typeof urls;

/**
 * Helper to generate react router routes for each page of the app.
 *
 * @param route
 */
export function urlFor<K extends keyof Urls>(route: K) {
  return urls[route];
}

// Apollo client instance to pass into the provider component
const client = createClient();

const AppComponent = (props: AppProps) => {
  const { history } = props;

  return (
    <ApolloProvider client={client}>
      <Grommet theme={voleer2}>
        <Box background="light-1" height={{ min: '100vh' }} overflow="auto">
          <VoleerGlobalStyles />
          <PendoInitialization />
          <Marketo />
          <Navbar />
          <Router history={history}>
            <Switch>
              <Route component={HomePage} exact={true} path="/" />
              <Route
                component={SelectSitePage}
                exact={true}
                path="/select-site"
              />
              <Route component={RegisterPage} exact={true} path="/register" />
              <Route
                component={ConfirmEmailPage}
                exact={true}
                path="/confirmEmail"
              />

              {/* ----- Errors ----- */}
              <Route component={Error404Page} />
            </Switch>
          </Router>
        </Box>
      </Grommet>
    </ApolloProvider>
  );
};

export const App = withRouter(AppComponent);

export default App;

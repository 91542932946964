import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
};

/** Account status to differentiate activity states for the Account. */
export enum AccountStatus {
  /** Default value. */
  Unknown = 'UNKNOWN',
  /** The Tenant account is currently in the trial period. */
  Trial = 'TRIAL',
  /** The account is active. */
  Active = 'ACTIVE',
  /** Trial has expired. */
  TrialExpired = 'TRIAL_EXPIRED',
  /** Subscription has been paused. */
  Paused = 'PAUSED',
  /** Subscription has been canceled. */
  Canceled = 'CANCELED',
  /** Tenant has been deactivated (can still be re-activated). */
  Deactivated = 'DEACTIVATED',
  /** Most tenant information has been removed; only tombstone remains for records. */
  Deprovisioned = 'DEPROVISIONED'
}

/** Add tenant Member input. */
export type AddTenantMemberInput = {
  /** First name of the user to add. */
  firstName: Scalars['String'];
  /** Last name of the user to add. */
  lastName: Scalars['String'];
  /** Email address of the user to add. */
  emailAddress: Scalars['String'];
  /** Tenant role of the user to add. */
  role: TenantMemberRole;
};

/** Add tenant member result. */
export type AddTenantMemberResult = {
  __typename?: 'AddTenantMemberResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<TenantsError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
  /** The tenant member that was created. */
  tenantMember?: Maybe<TenantMember>;
};

/** The input to cancel a custom workflow candidate configuration. */
export type CancelCustomWorkflowIntegrationConfigurationInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Contains the payload of canceling a custom workflow integration candidate configuration. */
export type CancelCustomWorkflowIntegrationConfigurationPayload = {
  __typename?: 'CancelCustomWorkflowIntegrationConfigurationPayload';
  /** The custom workflow integration with its candidate configuration canceled. */
  integration?: Maybe<CustomWorkflowIntegration>;
};

/** Change password input. */
export type ChangePasswordInput = {
  /** The new password. */
  newPassword: Scalars['String'];
  /** The current password. */
  currentPassword: Scalars['String'];
};

/** Represents an authentication . */
export type ChangePasswordResult = {
  __typename?: 'ChangePasswordResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<IdentityError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
};

export type ChargeBeePortalSessionObject = {
  __typename?: 'ChargeBeePortalSessionObject';
  /** Unique URL for accessing the customer portal. Once accessed, this cannot be reused. */
  access_url: Scalars['String'];
  /** Indicates the current status of the portal session. */
  created_at: Scalars['Float'];
  /** Identifier of the customer. */
  customer_id: Scalars['ID'];
  /** Timestamp indicating when the access URL will expire. Once expired, the URL cannot be used to login into the portal. */
  expires_at: Scalars['Float'];
  /** Unique identifier for the portal session. */
  id: Scalars['ID'];
  /** Object type. Defaults to 'portal_session'. */
  object: Scalars['String'];
  /** Indicates the current status of the portal session. */
  status: Scalars['String'];
  /** Unique pre-authenticated portal session token to access. */
  token: Scalars['String'];
};

/** Check normalized name availability. */
export type CheckNormalizedNameAvailability = {
  __typename?: 'CheckNormalizedNameAvailability';
  /** Is the Normalized name available. */
  isNormalizedNameAvailable: Scalars['Boolean'];
};

export type Claim = {
  __typename?: 'Claim';
  type: Scalars['String'];
  value: Scalars['String'];
};

/** User email confirmation input. */
export type ConfirmEmailInput = {
  /** Email verification code for the specified user. */
  verificationCode: Scalars['String'];
  /** The User id. */
  userId: Scalars['String'];
};

/** Email confirmation output. */
export type ConfirmEmailResult = {
  __typename?: 'ConfirmEmailResult';
  /** Id of the user that got its email confirmed */
  userId: Scalars['String'];
};

/** Contains the parameters to create an integration of custom workflow type. */
export type CreateCustomWorkflowIntegrationInput = {
  /** The display name. */
  displayName: Scalars['String'];
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
};

/** Contains the payload of creating a custom workflow integration. */
export type CreateCustomWorkflowIntegrationPayload = {
  __typename?: 'CreateCustomWorkflowIntegrationPayload';
  /** The created custom workflow integration. */
  integration?: Maybe<CustomWorkflowIntegration>;
};

/** The input to create an OAuth2 integration session. */
export type CreateOAuth2IntegrationSessionInput = {
  /** The display name. */
  displayName: Scalars['String'];
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The optional id linking the integration with a specific instance. */
  instanceId?: Maybe<Scalars['ID']>;
  /** The id of the workspace the optional instance linked to the integration belongs to. */
  workspaceId?: Maybe<Scalars['ID']>;
};

/** The result for the mutation to create an OAuth2 integration session. */
export type CreateOAuth2IntegrationSessionResult = {
  __typename?: 'CreateOAuth2IntegrationSessionResult';
  /** The integration id. */
  integrationId: Scalars['ID'];
  /** The session id. */
  sessionId: Scalars['String'];
};

/** Create notification subscription input. */
export type CreateRunNotificationSubscriptionInput = {
  /** The type of notification being subscribed to. */
  runNotificationType: RunNotificationType;
  /** The intial status state of the new subscription. */
  status: NotificationSubscriptionStatus;
  /** The workspace id. */
  workspaceId?: Maybe<Scalars['ID']>;
  /** The template configuration id. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The list of notifications channels enabled for the subscription. */
  channelTypes?: Maybe<Array<Maybe<NotificationChannelTypes>>>;
};

/** Contains the payload of creating a run notification subscription. */
export type CreateRunNotificationSubscriptionPayload = {
  __typename?: 'CreateRunNotificationSubscriptionPayload';
  /** The created run notification subscription. */
  notificationSubscription?: Maybe<RunNotificationSubscription>;
};

/** Graphql return object to the create tenant mutation. */
export type CreateTenantResult = {
  __typename?: 'CreateTenantResult';
  /** Return Url updated to use the soon to be created tenant domain tenant ui */
  returnUrl: Scalars['String'];
  /** Id of the tenant that was created */
  tenantId: Scalars['String'];
  /** Id of the first member of the tenant that was created */
  userId: Scalars['String'];
};

/** Input for the create workspace mutation. */
export type CreateWorkspaceInput = {
  /** The display name. */
  displayName: Scalars['String'];
  /** The location to create the workspace in. If excluded, defaults to the tenant's location. */
  location?: Maybe<Scalars['String']>;
};

/** Represents the integration of custom workflow type. */
export type CustomWorkflowIntegration = Integration & {
  __typename?: 'CustomWorkflowIntegration';
  /** The candidate instance id. */
  candidateInstanceId?: Maybe<Scalars['ID']>;
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration display name. */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  /** The instance id. */
  instanceId?: Maybe<Scalars['ID']>;
  /** The instance's workspace id. */
  instanceWorkspaceId: Scalars['ID'];
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The integration status. */
  status: IntegrationStatus;
};


/** Delete integration input. */
export type DeleteIntegrationInput = {
  /** Id of the integration to delete. */
  id: Scalars['ID'];
};

/** Delete notification subscription input. */
export type DeleteNotificationSubscriptionInput = {
  /** Id of the subscription to delete. */
  id: Scalars['ID'];
};

/** Contains the payload of deleting a notification subscription. */
export type DeleteNotificationSubscriptionPayload = {
  __typename?: 'DeleteNotificationSubscriptionPayload';
  /** The id of the deleted notification subscription. */
  id?: Maybe<Scalars['ID']>;
};

/** Delete tenant Member input. */
export type DeleteTenantMemberInput = {
  /** Id of the tenant member to delete. */
  id: Scalars['String'];
};

/** Delete Workspace input. */
export type DeleteWorkspaceInput = {
  /** Id of the workspace to delete. */
  id: Scalars['ID'];
};

/** Represents an instantiable class of  required for patch request deserialization. */
export type EditIntegration = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The description */
  description?: Maybe<Scalars['String']>;
};

/** Represents notifications that are assigned to a given . */
export type EditNotificationRecipient = {
  /** The status of this notification */
  status: NotificationRecipientStatus;
};

/** Contains the payload of an edited notification recipient. */
export type EditNotificationRecipientPayload = {
  __typename?: 'EditNotificationRecipientPayload';
  /** The edited notification recipient. */
  notificationRecipient?: Maybe<NotificationRecipient>;
};

/** Payload for the edit run subscription mutation. */
export type EditRunNotificationSubscriptionInput = {
  /** The type of notification being subscribed to. */
  runNotificationType?: Maybe<RunNotificationType>;
  /** The subscription status. */
  status?: Maybe<NotificationSubscriptionStatus>;
  /** The workspace. */
  workspace?: Maybe<WorkspaceInput>;
  /** The template configuration id. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The list of notifications channels enabled for the subscription. */
  notificationChannels?: Maybe<Array<Maybe<NotificationChannelTypes>>>;
};

/** Contains the payload of an edited run notification subscription. */
export type EditRunNotificationSubscriptionPayload = {
  __typename?: 'EditRunNotificationSubscriptionPayload';
  /** The edited run notification subscription. */
  notificationSubscription?: Maybe<RunNotificationSubscription>;
};

/** Edit tenant Member input. */
export type EditTenantMemberInput = {
  /** Id of the tenant member to edit. */
  id: Scalars['ID'];
  /** New first name of the tenant member. */
  firstName?: Maybe<Scalars['String']>;
  /** New last name of the tenant member. */
  lastName?: Maybe<Scalars['String']>;
  /** New email address of the tenant member. */
  emailAddress?: Maybe<Scalars['String']>;
  /** New role for the tenant member. */
  role?: Maybe<TenantMemberRole>;
  /** Whether or not the edited tenant member should be sent run summary email notifications. */
  sendRunSummaryViaEmail?: Maybe<Scalars['Boolean']>;
};

/** Edit tenant member result. */
export type EditTenantMemberResult = {
  __typename?: 'EditTenantMemberResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<TenantsError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
  /** The tenant member that was edited. */
  tenantMember?: Maybe<TenantMember>;
};

/** Input for the edit workspace mutation. */
export type EditWorkspaceInput = {
  /** Gets or sets the workspace id. */
  workspaceId: Scalars['ID'];
  /** The display name. */
  displayName: Scalars['String'];
};

export type IdentityError = {
  __typename?: 'IdentityError';
  /** Error code. */
  code: Scalars['String'];
  /** Error description. */
  description: Scalars['String'];
};

/** Represents an integration instance. */
export type Integration = {
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration display name. */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The integration status. */
  status: IntegrationStatus;
};

/** A connection from an object to a list of objects of type `IntegrationInterface`. */
export type IntegrationInterfaceConnection = {
  __typename?: 'IntegrationInterfaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<IntegrationInterfaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Integration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `IntegrationInterface`. */
export type IntegrationInterfaceEdge = {
  __typename?: 'IntegrationInterfaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Integration;
};

export type IntegrationPatchRequest = {
  id: Scalars['ID'];
  data: EditIntegration;
};

/** IntegrationReference describes a reference to a specific integration definition. */
export type IntegrationReference = {
  /** The fully-qualified package name. */
  package: Scalars['String'];
  /** The integration name. */
  integration: Scalars['String'];
};

/** Statuses of the . */
export enum IntegrationStatus {
  /** Unknown. */
  Unknown = 'UNKNOWN',
  /** Pending. */
  Pending = 'PENDING',
  /** Active. */
  Active = 'ACTIVE',
  /** Failed. */
  Failed = 'FAILED'
}

/** Input for retrieving s. */
export type IntegrationsRetrieveInput = {
  /** The optional object detailing the integration definition to match on */
  matchingIntegrations?: Maybe<MatchingIntegrationsInput>;
  /** The optional list of integration statuses to filter by */
  statuses?: Maybe<Array<IntegrationStatus>>;
};

/** DTO to expose as GraphQl result. */
export type LaunchControlItemMappings = {
  __typename?: 'LaunchControlItemMappings';
  /** ChargeBee plan. */
  chargeBeeSaleItem: Scalars['String'];
  /** List of templates. */
  templates: Array<Scalars['String']>;
};

/** Input to retrieve s matching specific parameters. */
export type MatchingIntegrationsInput = {
  /** The integration references. */
  integrationReferences: Array<IntegrationReference>;
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmEmail?: Maybe<ConfirmEmailResult>;
  createTenant?: Maybe<CreateTenantResult>;
  sendEmailConfirmationCode?: Maybe<SendEmailConfirmationCodeResult>;
};


export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type MutationCreateTenantArgs = {
  tenant: TenantInput;
  tenantUser: TenantUserInput;
  returnUrl?: Maybe<Scalars['String']>;
};


export type MutationSendEmailConfirmationCodeArgs = {
  input: SendEmailConfirmationCodeInput;
};

/** Represents the base functionality for notifications. */
export type Notification = {
  /** The created on date. */
  createdOn: Scalars['DateTime'];
  /** The id. */
  id: Scalars['ID'];
  /** The notification message. */
  message?: Maybe<Scalars['String']>;
  /** The notification display name. */
  subject: Scalars['String'];
};

/** Represents the notification channel types. */
export enum NotificationChannelTypes {
  /** None. */
  None = 'NONE',
  /** Email. */
  Email = 'EMAIL',
  /** VoleerUi. */
  VoleerUi = 'VOLEER_UI'
}

/** Represents notifications that are assigned to a given . */
export type NotificationRecipient = {
  __typename?: 'NotificationRecipient';
  /** The created on date. */
  createdOn: Scalars['DateTime'];
  /** The id. */
  id: Scalars['ID'];
  /** The notification. */
  notification: Notification;
  /** The notification subscription. */
  notificationSubscription?: Maybe<NotificationSubscription>;
  /** The status. */
  status: NotificationRecipientStatus;
};

/** A connection from an object to a list of objects of type `NotificationRecipient`. */
export type NotificationRecipientConnection = {
  __typename?: 'NotificationRecipientConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationRecipientEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<NotificationRecipient>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `NotificationRecipient`. */
export type NotificationRecipientEdge = {
  __typename?: 'NotificationRecipientEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NotificationRecipient;
};

export type NotificationRecipientPatchRequest = {
  id: Scalars['ID'];
  data: EditNotificationRecipient;
};

/** Represents the status of a notification sent to a tenant member. */
export enum NotificationRecipientStatus {
  /** None. */
  None = 'NONE',
  /** Unread. */
  Unread = 'UNREAD',
  /** Read. */
  Read = 'READ'
}

/** Input used to query s. */
export type NotificationRecipientsRetrieveInput = {
  /** The ids of the notification recipients */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The statuses of the notification recipients */
  statuses?: Maybe<Array<NotificationRecipientStatus>>;
  /** The types of the notification recipients */
  types?: Maybe<Array<NotificationType>>;
  /** The types of the notification channels */
  channelTypes?: Maybe<Array<NotificationChannelTypes>>;
};

/** Represents the base functionality of a notification subscription. */
export type NotificationSubscription = {
  /** The notification channel types enabled for this subscription. */
  channelTypes: Array<NotificationChannelTypes>;
  /** The id. */
  id: Scalars['ID'];
  /** The notification subscription status. */
  status: NotificationSubscriptionStatus;
};

/** A connection from an object to a list of objects of type `NotificationSubscriptionInterface`. */
export type NotificationSubscriptionInterfaceConnection = {
  __typename?: 'NotificationSubscriptionInterfaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationSubscriptionInterfaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<NotificationSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `NotificationSubscriptionInterface`. */
export type NotificationSubscriptionInterfaceEdge = {
  __typename?: 'NotificationSubscriptionInterfaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: NotificationSubscription;
};

/** Statuses of the . */
export enum NotificationSubscriptionStatus {
  /** None. */
  None = 'NONE',
  /** Active. */
  Active = 'ACTIVE',
  /** Paused. */
  Paused = 'PAUSED'
}

/** Input used to query s. */
export type NotificationSubscriptionsRetrieveInput = {
  /** The ids of the notification subscriptions */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** The statuses of the notification subscriptions */
  statuses?: Maybe<Array<NotificationSubscriptionStatus>>;
  /** The types of the notification subscriptions */
  types?: Maybe<Array<NotificationType>>;
};

/**
 * Represents a discrimator for different type of
 *             and  implementations.
 */
export enum NotificationType {
  /** None. */
  None = 'NONE',
  /** Run. */
  Run = 'RUN'
}

/** Represents an integration of type OAuth2. */
export type OAuth2Integration = Integration & {
  __typename?: 'OAuth2Integration';
  /** The description. */
  description?: Maybe<Scalars['String']>;
  /** The integration display name. */
  displayName: Scalars['String'];
  hasSession: Scalars['Boolean'];
  /** The id. */
  id: Scalars['ID'];
  /** The integration metadata id. */
  integrationMetadataId: Scalars['ID'];
  /** The integration status. */
  status: IntegrationStatus;
};


/** Represents an integration of type OAuth2. */
export type OAuth2IntegrationHasSessionArgs = {
  sessionId: Scalars['String'];
};

/** Input to retrieve an OAuth2 . */
export type OAuth2IntegrationRetrieveInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
  /** The session id. */
  sessionId?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  checkNormalizedNameAvailability: CheckNormalizedNameAvailability;
};


export type QueryCheckNormalizedNameAvailabilityArgs = {
  input: Scalars['String'];
};

/** A message containing information about a region info. */
export type RegionInfo = {
  __typename?: 'RegionInfo';
  /** The display name */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  /** The short name */
  shortName: Scalars['String'];
};

/** Resend invitation email input. */
export type ResendEmailInvitationInput = {
  /** Id of the tenant member to resend an invitation to. */
  tenantMemberId: Scalars['String'];
};

/** The input to reset an OAuth2 integration session. */
export type ResetCustomWorkflowIntegrationInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Contains the payload of resetting a custom workflow integration. */
export type ResetCustomWorkflowIntegrationPayload = {
  __typename?: 'ResetCustomWorkflowIntegrationPayload';
  /** The reset custom workflow integration. */
  integration?: Maybe<CustomWorkflowIntegration>;
};

/** The input to reset an OAuth2 integration session. */
export type ResetOAuth2IntegrationInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Represents a  of type Run. */
export type RunNotification = Notification & {
  __typename?: 'RunNotification';
  /** The created on date. */
  createdOn: Scalars['DateTime'];
  /** The id. */
  id: Scalars['ID'];
  /** The id of the instance related to this notification. */
  instanceId: Scalars['ID'];
  /** The notification message. */
  message?: Maybe<Scalars['String']>;
  /** The type of notification event that matched this notification to a subscription. */
  runNotificationType: RunNotificationType;
  /** The run status that triggered the notification. */
  runStatus: RunStatus;
  /** The id of the step related to this notification. */
  stepId?: Maybe<Scalars['ID']>;
  /** The notification subscription display name. */
  subject: Scalars['String'];
  /** The workspace associated with the instance run. */
  workspace: Workspace;
};

/** Represents a NotificationSubscription of type Run. */
export type RunNotificationSubscription = NotificationSubscription & {
  __typename?: 'RunNotificationSubscription';
  /** The notification channel types enabled for this subscription. */
  channelTypes: Array<NotificationChannelTypes>;
  /** The id. */
  id: Scalars['ID'];
  /** The type of run notification. */
  runNotificationType: RunNotificationType;
  /** The notification subscription status. */
  status: NotificationSubscriptionStatus;
  /** The optional template configuration id the subscription watches. */
  templateConfigurationId?: Maybe<Scalars['ID']>;
  /** The workspace if one is associated with this subscription. */
  workspace?: Maybe<Workspace>;
};

export type RunNotificationSubscriptionPatchRequest = {
  id: Scalars['ID'];
  data: EditRunNotificationSubscriptionInput;
};

/** Represents the notification types for instance runs. */
export enum RunNotificationType {
  /** None. */
  None = 'NONE',
  /** WaitingForInput. */
  WaitingForInput = 'WAITING_FOR_INPUT',
  /** Completed. */
  Completed = 'COMPLETED',
  /** Failed. */
  Failed = 'FAILED'
}

/** The status of the instance run that triggered the notification. */
export enum RunStatus {
  /** Unknown. */
  Unknown = 'UNKNOWN',
  /** Scheduled. */
  Scheduled = 'SCHEDULED',
  /** Running. */
  Running = 'RUNNING',
  /** Completed successfully. */
  Completed = 'COMPLETED',
  /** Completed with errors. */
  Failed = 'FAILED',
  /** End user or API requested cancellation. */
  Canceled = 'CANCELED',
  /** Pending; actively being scheduled. */
  Pending = 'PENDING',
  /** End user or API requested cancellation and it is currently being cancelled. */
  Cancelling = 'CANCELLING',
  /** Occurs when the instance takes longer than the allocated time to finish or if any of its steps have timed out. */
  TimedOut = 'TIMED_OUT',
  /** A step in the run is awaiting input. */
  WaitingForInput = 'WAITING_FOR_INPUT'
}

/** Sale item type. */
export enum SaleItemType {
  /** Default value. */
  Unknown = 'UNKNOWN',
  /** Plan sale item type. */
  Plan = 'PLAN',
  /** Addon sale item plan. */
  Addon = 'ADDON'
}

/** Send email confirmation code input. */
export type SendEmailConfirmationCodeInput = {
  /** Id of the user to send the email to. */
  userId: Scalars['String'];
  /** Return url to redirect after the user verifies email. */
  returnUrl?: Maybe<Scalars['String']>;
};

/** Send email confirmation code result. */
export type SendEmailConfirmationCodeResult = {
  __typename?: 'SendEmailConfirmationCodeResult';
  /** Id of the user for whom the confirmation code was created. */
  userId: Scalars['String'];
};

/** Set notification recipient status input. */
export type SetNotificationRecipientStatusByDateInput = {
  /** The status to set. */
  status: NotificationRecipientStatus;
  /** The date time that is greater than the last updated date time of the target recipients. */
  updatedBefore: Scalars['DateTime'];
};

/** Contains the payload of setting the status of a notification recipients by date. */
export type SetNotificationRecipientStatusByDateInputPayload = {
  __typename?: 'SetNotificationRecipientStatusByDateInputPayload';
  /** The number of notification recipients updated. */
  numberOfRecipientsUpdated: Scalars['Int'];
};

/** Set password input. */
export type SetPasswordInput = {
  /** Password reset code. */
  resetCode: Scalars['String'];
  /** New password. */
  password: Scalars['String'];
  /** New password confirmation. */
  confirmPassword: Scalars['String'];
  /** User's id. */
  userId: Scalars['String'];
};

/** Represents an authentication . */
export type SetPasswordResult = {
  __typename?: 'SetPasswordResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<IdentityError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
};

/** Adds profile data for application users by adding properties to the ApplicationUser class. */
export type SystemUser = {
  __typename?: 'SystemUser';
  claims?: Maybe<Array<Maybe<Claim>>>;
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  id: Scalars['String'];
  phoneNumberConfirmed: Scalars['Boolean'];
  userName: Scalars['String'];
};

/** Represents a tenant. */
export type Tenant = {
  __typename?: 'Tenant';
  /** The account standing status */
  accountStatus: AccountStatus;
  /** Tenant display name */
  displayName: Scalars['String'];
  /** The Tenant Id */
  id: Scalars['ID'];
  /** Tenant normalized name */
  name: Scalars['String'];
  /** The next status date. */
  nextStatusDate?: Maybe<Scalars['DateTime']>;
  /** The name of the plan */
  planName: Scalars['String'];
  /** The collection of plans, addons etc */
  tenantSaleItems: Array<TenantSaleItem>;
};

/** A model defining the tenant identity provider. */
export type TenantIdpModel = {
  __typename?: 'TenantIdpModel';
  /** If true, the tenant uses an external identity provider, otherwise it uses Voleer */
  externalProviderEnabled: Scalars['Boolean'];
  /** The configured name of the identity provider, also called providerId. Not user friendly. */
  providerName: Scalars['String'];
};

/** Input representing a tenant. */
export type TenantInput = {
  /** Tenant name. */
  name: Scalars['String'];
  /** Tenant display name. */
  displayName: Scalars['String'];
};

/** Represents a link between a user and a . */
export type TenantMember = {
  __typename?: 'TenantMember';
  /** Tenant member email address */
  emailAddress: Scalars['String'];
  /** Tenant member first name */
  firstName: Scalars['String'];
  /** Tenant member Id */
  id: Scalars['ID'];
  /** Is the Tenant member active */
  isActive: Scalars['Boolean'];
  /** Tenant member last name */
  lastName: Scalars['String'];
  /** Last date at which the tenant member was seen */
  lastSeenOn?: Maybe<Scalars['DateTime']>;
  /** Tenant member role */
  role: TenantMemberRole;
  /** Whether or not the tenant member will be sent run summary email notifications. */
  sendRunSummaryViaEmail: Scalars['Boolean'];
  /** Id of the SystemUser associated with this tenant member */
  systemUserId?: Maybe<Scalars['String']>;
  /** Tenant of this tenant member */
  tenant: Tenant;
};

/** Tenant Member role. */
export enum TenantMemberRole {
  /** Default value. */
  Unknown = 'UNKNOWN',
  /** Administrator role. */
  Admin = 'ADMIN',
  /** User role. */
  User = 'USER'
}

/** Input for retrieving tenant members. */
export type TenantMembersRetrieveInput = {
  /** Ids of the tenant member to retrieve. */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** System user ids of the tenant members to retrieve. */
  systemUserIds?: Maybe<Array<Scalars['String']>>;
  /** Filter on active or inactive users. */
  isActive?: Maybe<Scalars['Boolean']>;
};

/** Represents tenants sales item. */
export type TenantSaleItem = {
  __typename?: 'TenantSaleItem';
  /** ChargeBee internal plan or addon Id */
  chargeBeeItemId: Scalars['String'];
  /** Tenant sales item display name */
  displayName: Scalars['String'];
  /** The Tenant sales item Id */
  id: Scalars['ID'];
  /** The Tenant sales item type Id */
  itemType: SaleItemType;
  /** The quantity of the item */
  quantity?: Maybe<Scalars['Int']>;
  /** Tenant sales item last updated date */
  updatedOn?: Maybe<Scalars['DateTime']>;
};

/** GraphQL tenant user. */
export type TenantUserInput = {
  /** First name of the first member of the tenant */
  firstName: Scalars['String'];
  /** Last name of the first member of the tenant */
  lastName: Scalars['String'];
  /** Email address of the first member of the tenant */
  emailAddress: Scalars['String'];
  /** Password used to access the first member of the tenant */
  password: Scalars['String'];
};

/** Represents a . */
export type TenantsApiResult = {
  __typename?: 'TenantsApiResult';
  /** A list of errors that occurred during the operation. */
  errors: Array<TenantsError>;
  /** Flag indicating whether the operation succeeded. */
  isSuccessful: Scalars['Boolean'];
};

/** Encapsulates an error from the tenants subsystem. */
export type TenantsError = {
  __typename?: 'TenantsError';
  /** Error code. */
  code: Scalars['String'];
  /** Error description. */
  description: Scalars['String'];
};

/** The input to trigger an integration refresh. */
export type TriggerIntegrationRefreshInput = {
  /** The integration id. */
  integrationId: Scalars['ID'];
};

/** Contains the payload of triggering an integration schedule refresh operation. */
export type TriggerIntegrationRefreshPayload = {
  __typename?: 'TriggerIntegrationRefreshPayload';
  /** The integration which has its refresh triggered. */
  integration?: Maybe<Integration>;
};

/** Represents a workspace. */
export type Workspace = {
  __typename?: 'Workspace';
  /** The display name */
  displayName: Scalars['String'];
  /** The id. */
  id: Scalars['ID'];
  /** The information about the region the workspace is in */
  regionInfo: RegionInfo;
  /** The tenant. */
  tenant: Tenant;
};

/** A connection from an object to a list of objects of type `Workspace`. */
export type WorkspaceConnection = {
  __typename?: 'WorkspaceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<WorkspaceEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<Workspace>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Workspace`. */
export type WorkspaceEdge = {
  __typename?: 'WorkspaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Workspace>;
};

/** Represents a workspace. */
export type WorkspaceInput = {
  /** The workspace id. */
  id: Scalars['ID'];
};

/** Input for retrieving workspaces. */
export type WorkspaceRetrieveInput = {
  /** Display names to filter by. */
  displayNames?: Maybe<Array<Scalars['String']>>;
};

export type CreateTenantMutationVariables = Exact<{
  tenant: TenantInput;
  tenantUser: TenantUserInput;
  returnUrl: Scalars['String'];
}>;


export type CreateTenantMutation = (
  { __typename?: 'Mutation' }
  & { createTenant?: Maybe<(
    { __typename?: 'CreateTenantResult' }
    & Pick<CreateTenantResult, 'userId' | 'tenantId' | 'returnUrl'>
  )> }
);

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & { confirmEmail?: Maybe<(
    { __typename?: 'ConfirmEmailResult' }
    & Pick<ConfirmEmailResult, 'userId'>
  )> }
);

export type SendEmailConfirmationCodeMutationVariables = Exact<{
  input: SendEmailConfirmationCodeInput;
}>;


export type SendEmailConfirmationCodeMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailConfirmationCode?: Maybe<(
    { __typename?: 'SendEmailConfirmationCodeResult' }
    & Pick<SendEmailConfirmationCodeResult, 'userId'>
  )> }
);

export type CheckNormalizedNameAvailabilityQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type CheckNormalizedNameAvailabilityQuery = (
  { __typename?: 'Query' }
  & { checkNormalizedNameAvailability: (
    { __typename?: 'CheckNormalizedNameAvailability' }
    & Pick<CheckNormalizedNameAvailability, 'isNormalizedNameAvailable'>
  ) }
);


export const CreateTenantDocument = gql`
    mutation CreateTenant($tenant: TenantInput!, $tenantUser: TenantUserInput!, $returnUrl: String!) {
  createTenant(tenant: $tenant, tenantUser: $tenantUser, returnUrl: $returnUrl) {
    userId
    tenantId
    returnUrl
  }
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;
export type CreateTenantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTenantMutation, CreateTenantMutationVariables>, 'mutation'>;

    export const CreateTenantComponent = (props: CreateTenantComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTenantMutation, CreateTenantMutationVariables> mutation={CreateTenantDocument} {...props} />
    );
    

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      tenantUser: // value for 'tenantUser'
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($input: ConfirmEmailInput!) {
  confirmEmail(input: $input) {
    userId
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export type ConfirmEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>, 'mutation'>;

    export const ConfirmEmailComponent = (props: ConfirmEmailComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmEmailMutation, ConfirmEmailMutationVariables> mutation={ConfirmEmailDocument} {...props} />
    );
    

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const SendEmailConfirmationCodeDocument = gql`
    mutation SendEmailConfirmationCode($input: SendEmailConfirmationCodeInput!) {
  sendEmailConfirmationCode(input: $input) {
    userId
  }
}
    `;
export type SendEmailConfirmationCodeMutationFn = Apollo.MutationFunction<SendEmailConfirmationCodeMutation, SendEmailConfirmationCodeMutationVariables>;
export type SendEmailConfirmationCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailConfirmationCodeMutation, SendEmailConfirmationCodeMutationVariables>, 'mutation'>;

    export const SendEmailConfirmationCodeComponent = (props: SendEmailConfirmationCodeComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailConfirmationCodeMutation, SendEmailConfirmationCodeMutationVariables> mutation={SendEmailConfirmationCodeDocument} {...props} />
    );
    

/**
 * __useSendEmailConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailConfirmationCodeMutation, { data, loading, error }] = useSendEmailConfirmationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailConfirmationCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailConfirmationCodeMutation, SendEmailConfirmationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailConfirmationCodeMutation, SendEmailConfirmationCodeMutationVariables>(SendEmailConfirmationCodeDocument, options);
      }
export type SendEmailConfirmationCodeMutationHookResult = ReturnType<typeof useSendEmailConfirmationCodeMutation>;
export type SendEmailConfirmationCodeMutationResult = Apollo.MutationResult<SendEmailConfirmationCodeMutation>;
export type SendEmailConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<SendEmailConfirmationCodeMutation, SendEmailConfirmationCodeMutationVariables>;
export const CheckNormalizedNameAvailabilityDocument = gql`
    query CheckNormalizedNameAvailability($input: String!) {
  checkNormalizedNameAvailability(input: $input) {
    isNormalizedNameAvailable
  }
}
    `;
export type CheckNormalizedNameAvailabilityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables>, 'query'> & ({ variables: CheckNormalizedNameAvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckNormalizedNameAvailabilityComponent = (props: CheckNormalizedNameAvailabilityComponentProps) => (
      <ApolloReactComponents.Query<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables> query={CheckNormalizedNameAvailabilityDocument} {...props} />
    );
    

/**
 * __useCheckNormalizedNameAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckNormalizedNameAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckNormalizedNameAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckNormalizedNameAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckNormalizedNameAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables>(CheckNormalizedNameAvailabilityDocument, options);
      }
export function useCheckNormalizedNameAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables>(CheckNormalizedNameAvailabilityDocument, options);
        }
export type CheckNormalizedNameAvailabilityQueryHookResult = ReturnType<typeof useCheckNormalizedNameAvailabilityQuery>;
export type CheckNormalizedNameAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckNormalizedNameAvailabilityLazyQuery>;
export type CheckNormalizedNameAvailabilityQueryResult = Apollo.QueryResult<CheckNormalizedNameAvailabilityQuery, CheckNormalizedNameAvailabilityQueryVariables>;
import styled, { keyframes } from 'styled-components';
import { Icon } from '../Icon';

const spin = keyframes`
  100% { transform: rotate(360deg); }
`;

/**
 * Renders a rotating icon.
 */
export const RotatingIcon = styled(Icon)`
  animation: ${spin} 2s linear;
  animation-iteration-count: infinite;
`;
